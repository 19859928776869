import React, { Ref, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localize } from "src/l10n";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import { Style } from "src/ui/helpers";
import "./SystemStatusResourceCTA.scss";
import { Conditional } from "src/components/Conditional";
import InteractiveTextInput from "src/spintr/components/InteractiveTextInput";
import { CaptionBolder } from "src/components";
import classNames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import api from "src/spintr/SpintrApi";
import { addRemotePost } from "src/social-feed";
import SpintrLoader from "src/ui/components/Loader";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";

type Props = {
    resource: Spintr.ISystemStatusResource;
};

type State = {
    text:       string;
    isAsking:   boolean;
    isSaving:   boolean;
}

function SystemStatusResourceCTA({ resource }: Props) {
    const inputRef = useRef<InteractiveTextInput>(null);
    const dispatch = useDispatch();
    const [state, setState] = useState<State>({
        text: "",
        isAsking: false,
        isSaving: false,
    });

    const currentUser = useSelector<Spintr.AppState, Spintr.IActiveUser>(
        (state) => state.profile.active,
    );
    
    const primaryColor = useSelector<Spintr.AppState, string>(
        (state) => state.instance.get("theme").primaryColor || state.instance.get("primaryColor") || "#0078d4",
    );

    const onCtaClicked = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setState((prevState) => ({
            ...prevState,
            isAsking: true,
        }));

        setTimeout(() => {
            if (!inputRef.current) {
                return;
            }

            inputRef.current.giveFocus();
        }, 10);
    }, [setState]);

    const onSubmitted = useCallback(async () => {
        if (!inputRef.current) {
            return;
        }

        const text = inputRef.current.getContent()?.trim();
        if (text.length === 0) {
            return;
        }

        setState((prevState) => ({ ...prevState, isSaving: true }));

        const formData = new FormData();
        formData.append("jsonString", JSON.stringify({
            content: [],
            feedId: resource.feedId,
            text,
            flag: 0,
            StatusType: 0,
            Type: 3,
            Id: 0,
        }));

        try {
            const response = await api.post("/api/v1/status", formData);
            if (response.status > 399) {
                console.error(response.data);
                setState((prevState) => ({ ...prevState, isSaving: false }));
                return;
            }

            dispatch(addRemotePost(response.data, false));
        } catch (err) {
            console.log(err);

            setState((prevState) => ({ ...prevState, isSaving: false }));
            return;
        }

        setState((prevState) => ({
            ...prevState,
            text: "",
            isAsking: false,
            isSaving: false,
        }));
    }, [inputRef, resource.feedId]);

    if (resource.userCanPost || !resource.feedId) {
        return null;
    }

    if (state.isSaving) {
        return <SpintrLoader loaderSize="small" />;
    }

    const iconColor = getLightOrDarkColorBasedOnColor(
        primaryColor,
        "white",
        "black"
    ) as spintrColors;

    return (
        <div className={classNames("SystemStatusResourceCTA", {
                "SystemStatusResourceCTA--asking": state.isAsking
            })}
             onClick={onCtaClicked}>

            <SpintrUser
                hideText={true}
                imageUrl={currentUser.images["topBar"]}
                name={currentUser.name}
                personalName={true}
                size={Style.cirlceTiny} />

            <div className="SystemStatusResourceCTA-inner">
                
                <Conditional condition={!state.isAsking}>
                    <CaptionBolder color="contentLight">
                        {localize("COMPOSER_QUESTION")}
                    </CaptionBolder>
                </Conditional>

                <Conditional condition={state.isAsking}>
                    <Label
                        as="div"
                        className="SystemStatusResourceCTA-inputWrapper"
                        color="grey"
                        size="body-2"
                    >
                        <InteractiveTextInput
                            initialContent={""}
                            maxLength={200}
                            onEnter={onSubmitted}
                            placeholder={localize("COMPOSER_QUESTION")}
                            ref={inputRef} />
                    </Label>
                    <UnstyledButton
                        className="SystemStatusResourceCTA-sendButton primaryBGColor"
                        onClick={onSubmitted}
                        title={localize("Skapa")}
                        ariaLabel={localize("Skapa")}
                    >
                        <Visage2Icon
                            color={iconColor}
                            icon="send-1"
                            size="small" />
                    </UnstyledButton>
                </Conditional>

            </div>         
        </div>
    );
}

export default SystemStatusResourceCTA;

