import { ChoiceGroup, DatePicker, DayOfWeek, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton, SpinButton, TextField } from "@fluentui/react";
import moment from "moment";
import React from "react";
import { localize } from "src/l10n";
import { Label, UnstyledButton } from "src/ui";
import { FormControl, FormSection } from "src/ui/components/Forms";
import PopupHeader from "src/ui/components/PopupHeader";
import { capitalizeFirstLetter } from "src/utils";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import './CalendarEventRecurringSelector.scss';

interface IRecurringEventConfig {
    freq: string;
    interval: number;
    dtStart: Date;
    until: Date;
    byDay?: string;
    byMonthDate?: number;
    bySetPos?: number;
}

interface IProps {
    config?: IRecurringEventConfig;
    defaultStartDate?: Date;
    onChange: any;
}

const weekDays = [{
    index: 0,
    key: "MO",
    label: "DayOfWeek1",
}, {
    index: 1,
    key: "TU",
    label: "DayOfWeek2",
}, {
    index: 2,
    key: "WE",
    label: "DayOfWeek3",
}, {
    index: 3,
    key: "TH",
    label: "DayOfWeek4",
}, {
    index: 4,
    key: "FR",
    label: "DayOfWeek5",
}, {
    index: 5,
    key: "SA",
    label: "DayOfWeek6",
}, {
    index: 6,
    key: "SU",
    label: "DayOfWeek7",
}];

const setPosAlternatives = [
    { key: 1, text: "FIRST" },
    { key: 2, text: "SECOND" },
    { key: 3, text: "THIRD" },
    { key: 4, text: "FOURTH" },
    { key: -1, text: "LAST" },
];

const processConfig = (config: any, defaultStartDate: Date, selectedMonthlyMethod?: string, forSave?: boolean) => {
    const defaultDtStart = defaultStartDate ? defaultStartDate : new Date();
    const defaultUntil = new Date();

    defaultUntil.setDate(defaultDtStart.getDate() + 7);

    const dtStart = !config.dtStart ?
        defaultDtStart :
        ((config.dtStart instanceof Date) ?
            config.dtStart :
            new Date(config.dtStart));

    const until = !config.until ?
        defaultUntil :
        ((config.until instanceof Date) ?
            config.until :
            new Date(config.until));

    let processedConfig = {
        ...config,
        freq: config.freq ?? "DAILY",
        interval: config.interval ?? 1,
        dtStart,
        until
    }

    if (processedConfig.freq === "WEEKLY") {
        if (!processedConfig.byDay) {
            const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

            processedConfig.byDay = days[dtStart.getDay()];
        }

        let byDays = processedConfig.byDay.split(",");

        byDays = byDays.sort((a, b) => {
            let aIndex = weekDays.find(x => x.key === a).index;
            let bIndex = weekDays.find(x => x.key === b).index;

            return aIndex > bIndex;
        });

        processedConfig.byDay = byDays.join(",");
    } else if (processedConfig.freq === "MONTHLY") {
        if (forSave) {
            if (selectedMonthlyMethod === "0") {
                processedConfig.bySetPos = 0;
                processedConfig.byDay = "";
            } else {
                processedConfig.byMonthDate = 0;
            }
        } else {
            if (!processedConfig.byMonthDate) {
                processedConfig.byMonthDate = defaultDtStart.getDate();
            }

            if (!processedConfig.bySetPos &&
                processedConfig.bySetPos !== -1) {
                processedConfig.bySetPos = 1;
            }

            if (!processedConfig.byDay) {
                processedConfig.byDay = "MO";
            }
        }
    }

    processedConfig.timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "";

    return processedConfig;
}

const getRecurringString = (config: any, selectedMonthlyMethod: string) => {
    let s = "";

    if (config.freq === "DAILY") {
        s = config.interval === 1 ?
            localize("REPEATED_EVERY_DAY") :
            (localize("REPEATED_EVERY_X_DAY").replace("{0}", config.interval));
    } else if (config.freq === "WEEKLY") {
        const byDaySplitted = config.byDay.split(",");

        const dayNames = byDaySplitted.map((day: string) => {
            const weekDay = weekDays.find(x => x.key === day);

            if (weekDay) {
                return localize(weekDay.label).toLowerCase();
            } else {
                return "";
            }
        }).filter(x => !!x).join(", ");

        s = (config.interval === 1 ?
            localize("REPEATED_EVERY_WEEK") :
            (localize("REPEATED_EVERY_X_WEEK").replace("{0}", config.interval))) + " " + localize("Pa") + " " + dayNames;
    } else {
        s = config.interval === 1 ?
            localize("REPEATED_EVERY_MONTH") :
            (localize("REPEATED_EVERY_X_MONTH").replace("{0}", config.interval));

        if (selectedMonthlyMethod === "0") {
            s += (" " +
                localize("REC_BY_DATE").toLowerCase() +
                " " +
                config.byMonthDate);
        } else {
            let setPosAlternative = setPosAlternatives.find(x => x.key === config.bySetPos);

            if (!setPosAlternative) {
                setPosAlternative = setPosAlternatives[0];
            }

            let weekDay = weekDays.find(x => x.key === config.byDay);

            if (!weekDay) {
                weekDay = weekDays[0];
            }

            s += (" " +
                localize("REC_BY_DAY").toLowerCase() +
                " " +
                localize(setPosAlternative.text).toLowerCase() +
                " " +
                localize(weekDay.label).toLowerCase());
        }
    }

    s += (" " +
        localize("Fran").toLowerCase() +
        " " +
        moment(config.dtStart).format("YYYY-MM-DD") +
        " " +
        localize("Till").toLowerCase() +
        " " +
        moment(config.until).format("YYYY-MM-DD")) +
        ".";

    return s;
}

const CalendarEventRecurringSelector = (props: IProps) => {
    const [displaySelector, setDisplaySelector] = React.useState(false);
    const [config, setConfig] = React.useState(props.config);
    const [selectedMonthlyMethod, setSelectedMonthlyMethod] = React.useState(!!config && config.byMonthDate === 0 ? "1" : "0");

    return (
        <div className="CalendarEventRecurringSelector">
            <Dropdown
                label={localize("RECURRENCE")}
                selectedKey={props.config?.freq || "NEVER"}
                onChange={(_e, v) => {
                    if (v.key === "EDIT") {
                        setConfig(processConfig({
                            ...config,
                            freq: !!config && config.freq ? config.freq : "DAILY"
                        }, props.defaultStartDate));

                        setDisplaySelector(true);

                        return;
                    }

                    setConfig(processConfig({
                        ...config,
                        freq: v.key
                    }, props.defaultStartDate));

                    if (v.key === "NEVER") {
                        props.onChange(undefined);
                    } else {
                        setDisplaySelector(true);
                    }
                }}
                options={[
                    { key: "NEVER", text: localize("Aldrig") },
                    { key: "DAILY", text: localize("Dag") },
                    { key: "WEEKLY", text: localize("Vecka") },
                    { key: "MONTHLY", text: localize("Manad") },
                    { key: "EDIT", text: localize("Andra") }
                ]}
                styles={{ dropdown: { width: 250 } }}
            />
            {!!props.config && props.config.freq !== "NEVER" && (
                <Label className="rec-info-text" size="body-2" weight="medium">{getRecurringString(props.config, selectedMonthlyMethod)}</Label>
            )}
            {displaySelector && (
                <Dialog
                    hidden={!displaySelector}
                    containerClassName="dialogWithPopupHeader CalendarEventRecurringSelector-dialog"
                    onDismiss={() => {
                        setDisplaySelector(false);
                    }}
                    modalProps={{
                        isBlocking: true,
                        allowTouchBodyScroll: true
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: localize("RECURRENCE"),
                        closeButtonAriaLabel: localize("Stang"),
                    }}
                >
                    <PopupHeader
                        text={localize("RECURRENCE")}
                        onClose={() => {
                            setDisplaySelector(false);
                        }} />
                    <FormSection>
                        <FormControl>
                            <DatePicker
                                //style={{ maxWidth: 400 }}
                                label={localize("RECURRENCE_START_DATE")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={formatDatePickerDate}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={config.dtStart ?? new Date()}
                                isRequired={true}
                                onSelectDate={(date: Date) => {
                                    setConfig({
                                        ...config,
                                        dtStart: date
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <DatePicker
                                //style={{ maxWidth: 400 }}
                                label={localize("RECURRENCE_END_DATE")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={formatDatePickerDate}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={config.until ?? new Date()}
                                isRequired={true}
                                onSelectDate={(date: Date) => {
                                    setConfig({
                                        ...config,
                                        until: date
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl className="inlineChildren" label={localize("REC_REPEAT_EVERY")} required>
                            <SpinButton
                                className="spinButton"
                                defaultValue={config.interval.toString()}
                                min={0}
                                max={100}
                                step={1}
                                onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                    setConfig({
                                        ...config,
                                        interval: parseInt(newValue)
                                    });
                                }}
                            />
                            <Dropdown
                                selectedKey={config?.freq}
                                onChange={(_e, v) => {
                                    setConfig(processConfig({
                                        ...config,
                                        freq: v.key
                                    }, props.defaultStartDate));

                                    if (v.key !== "NEVER") {
                                        setDisplaySelector(true);
                                    }
                                }}
                                options={[
                                    { key: "DAILY", text: localize("Dag") },
                                    { key: "WEEKLY", text: localize("Vecka") },
                                    { key: "MONTHLY", text: localize("Manad") },
                                ]}
                                styles={{ dropdown: { width: 100 } }}
                            />
                        </FormControl>
                        {config.freq === "WEEKLY" && (
                            <FormControl>
                                <div className="weekDaySelector">
                                    {weekDays.map((weekDay: any, index: number) => {
                                        const isSelected = config.byDay.indexOf(weekDay.key) > -1;

                                        return (
                                            <UnstyledButton
                                                className={isSelected ? "selected" : ""}
                                                key={index}
                                                onClick={() => {
                                                    if (isSelected) {
                                                        let dates = config.byDay.split(",");

                                                        let newByDay = dates.filter(x => x !== weekDay.key).join(",");

                                                        setConfig(processConfig({
                                                            ...config,
                                                            byDay: newByDay
                                                        }, props.defaultStartDate));
                                                    } else {
                                                        let dates = config.byDay.split(",");

                                                        dates.push(weekDay.key);

                                                        let newByDay = dates.join(",");

                                                        setConfig(processConfig({
                                                            ...config,
                                                            byDay: newByDay
                                                        }, props.defaultStartDate));
                                                    }
                                                }}>
                                                <Label size="body-2">{localize(weekDay.label)[0].toUpperCase()}</Label>
                                            </UnstyledButton>
                                        )
                                    })}
                                </div>
                            </FormControl>
                        )}
                        {config.freq === "MONTHLY" && (
                            <FormControl>
                                <ChoiceGroup
                                    defaultSelectedKey={selectedMonthlyMethod}
                                    options={[
                                        {
                                            key: "0",
                                            text: localize("REC_BY_DATE"),
                                            onRenderField: (props, render) => {
                                                return (
                                                    <div className="ChoiceGroup-option-inline">
                                                        {render!(props)}
                                                        <SpinButton
                                                            disabled={selectedMonthlyMethod !== "0"}
                                                            className="spinButton"
                                                            defaultValue={config.byMonthDate.toString()}
                                                            min={0}
                                                            max={31}
                                                            step={1}
                                                            onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                                                setConfig({
                                                                    ...config,
                                                                    byMonthDate: parseInt(newValue)
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            key: "1",
                                            text: localize("REC_BY_DAY"),
                                            onRenderField: (props2, render) => {
                                                return (
                                                    <div className="ChoiceGroup-option-inline">
                                                        {render!(props2)}
                                                        <div>
                                                        <Dropdown
                                                            disabled={selectedMonthlyMethod !== "1"}
                                                            selectedKey={config?.bySetPos}
                                                            onChange={(_e, v) => {
                                                                setConfig(processConfig({
                                                                    ...config,
                                                                    bySetPos: v.key
                                                                }, props.defaultStartDate));
                                                            }}
                                                            options={setPosAlternatives.map(x => {
                                                                return {
                                                                    ...x,
                                                                    text: localize(x.text)
                                                                }
                                                            })}
                                                            styles={{ dropdown: { width: 40, marginBottom:12 } }}
                                                        />
                                                        <Dropdown
                                                            disabled={selectedMonthlyMethod !== "1"}
                                                            selectedKey={config?.byDay}
                                                            onChange={(_e, v) => {
                                                                setConfig(processConfig({
                                                                    ...config,
                                                                    byDay: v.key
                                                                }, props.defaultStartDate));
                                                            }}
                                                            options={weekDays.map((item: any, index: number) => {
                                                                return {
                                                                    key: item.key,
                                                                    text: capitalizeFirstLetter(localize(item.label))
                                                                }
                                                            })}
                                                            styles={{ dropdown: { width: 40 } }}
                                                        />
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                    onChange={(e, v) => {
                                        setSelectedMonthlyMethod(v.key);
                                    }}
                                    required={true}
                                    aria-required={true}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <Label size="body-2">{getRecurringString(config, selectedMonthlyMethod)}</Label>
                        </FormControl>
                    </FormSection>
                    <DialogFooter>
                        <DefaultButton
                            onClick={() => {
                                setConfig(props.config);
                                setDisplaySelector(false);
                            }}
                            text={localize("Avbryt")} />
                        <PrimaryButton
                            onClick={() => {
                                setDisplaySelector(false);

                                props.onChange(processConfig(config, props.defaultStartDate, selectedMonthlyMethod, true));
                            }}
                            text={localize("Spara")} />
                    </DialogFooter>
                </Dialog>
            )}
        </div>
    )
}

export default CalendarEventRecurringSelector;