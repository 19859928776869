import React from 'react';
import "./MessageObjects.scss";
import { ImageCore, Label } from 'src/ui';
import { ExternalFile } from 'src/chat';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import MessageTodoList from '../MessageTodoList/MessageTodoList';

interface IProps {
    message: Spintr.IChatMessage;
}

const MessageObjects = (props: IProps) => {
    if (!props.message.objects || props.message.objects.length === 0) {
        return null;
    }

    return (
        <div className={"MessageObjects"}>
            {props.message.objects.map((o: any) => {
                if (!o) {
                    return null;
                }

                if (o.type === 28) {
                    return (
                        <MessageTodoList key={o.id} data={o} />
                    )
                }

                if (o.fileType === 4) {
                    return (
                        <ImageCore
                            key={o.id}
                            openPhotoBoxOnClick
                            imageInChat={true}
                            imageId={o.id}
                            alt={o.name}
                            //@ts-ignore
                            src={o.thumbnailUrl || o.path}
                            title={o.name}
                        />
                    )
                }
    
                if (o.type === 7) {
                    return (
                        <a className="file-attachment" key={o.id} href={`/goto/${o.id}`} target="_blank">
                            <Visage2Icon icon="arrow-down-2" />
                            <Label color="dark-grey" size="body-2" title={o.name}>{o.name}</Label>
                        </a>
                    )
                }

                if (o.type === 74) {
                    return (
                        <ExternalFile
                            key={o.id}
                            hideActions
                            externalId={o.externalId || o.data.id}
                            source={o.source || o.data.source} />
                    )
                }

                return null;
            })}
        </div>
    )
}

export default MessageObjects;
