import React, {  } from "react";
import "./SystemStatus.scss";
import SystemStatusResourceInfo from "./SystemStatusResourceInfo";
import { SpintrTypes } from "src/typings";
import { Style } from "src/ui/helpers";
import { Label } from "src/ui";
import { decodeHtmlEntities, formatInteractiveText } from "src/utils";
import SystemStatusResourceCTA from "./SystemStatusResourceCTA";
import { localize } from "src/l10n";
import moment from "moment";
import { Link } from "react-router-dom";
import { Conditional } from "src/components/Conditional";
import classNames from "classnames";

interface IProps {
    resource: Spintr.ISystemStatusResource;
    systemStatus: any;
}

const SystemStatus = (props: IProps) => {
    const colors = [
        Style.getHexFromSpintrColor("green"),
        Style.getHexFromSpintrColor("red"),
        Style.getHexFromSpintrColor("green"),
        Style.getHexFromSpintrColor("orange")
    ];

    const renderInfoRow = (key: string, value: string, valueTitle?: string, className?: string | undefined) => {
        return (
            <div className={classNames("info-row", className)}>
                <Label size="body-2" color="grey">{key}</Label>
                <Label className="value-cell" size="body-2" color="dark-grey" title={valueTitle}>{value}</Label>
            </div>
        )
    }

    return (
        <Link
            className="SystemStatus"
            style={{
                borderColor: colors[props.systemStatus.status]
            }}
            to={"/operations/" + props.resource.id}>
            <div className="segment">
                <SystemStatusResourceInfo resource={{
                    ...props.resource,
                    status: props.systemStatus.status
                }} />
            </div>
            <div className="segment">
                {props.systemStatus.text && (
                    <Label 
                        as="div"
                        color="dark-grey"
                        className="general-row-break interactive-text feed-post-text"
                    >
                        {formatInteractiveText(
                            decodeHtmlEntities(props.systemStatus.text)
                        )}
                    </Label>
                )}
                <Conditional condition={!props.resource.userCanPost}>
                    <div className="footer-button">
                        <SystemStatusResourceCTA resource={props.resource} />
                    </div>
                </Conditional>
            </div>
            {/*<div className="spacer" />*/}
            <div className="segment">
                <div className="info">
                    {renderInfoRow(localize("Status"), localize(
                        props.systemStatus.status === SpintrTypes.SystemStatusType.AllClear
                            ? "ALL_CLEAR"
                            : props.systemStatus.status === SpintrTypes.SystemStatusType.Ongoing
                            ? "Pagaende"
                            : props.systemStatus.status === SpintrTypes.SystemStatusType.Planned
                            ? "Planerad"
                            : "Avklarad"
                    ), undefined, props.systemStatus.status === SpintrTypes.SystemStatusType.Ongoing ? "ongoing" : undefined)}
                    {renderInfoRow(localize("Start"), moment(props.systemStatus.startDate).calendar({ sameElse: "lll" }), moment(props.systemStatus.startDate).format("LLL"))}
                    {props.systemStatus.endDate	&& renderInfoRow(localize("Slut"), moment(props.systemStatus.endDate).calendar({ sameElse: "lll" }), moment(props.systemStatus.endDate).format("LLL"))}
                    {props.systemStatus.responsibleUser	&& renderInfoRow(localize("RESPONSIBLE_PERSON"), props.systemStatus.responsibleUser.name)}
                </div>
            </div>
        </Link>
    )
}

export default SystemStatus;
