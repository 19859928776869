import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { getFileIconUrl } from 'src/office365/utils';
import api from "src/spintr/SpintrApi";
import { DivWithHoverActions, Label, Loader } from "src/ui";
import { ShareModal } from '../ShareModal';
import "./ExternalFile.scss";
import { SpintrTypes } from 'src/typings';

interface IProps {
    externalId?: string,
    externalFile?: any,
    source?: any;
    hideShare?: boolean;
    hideActions?: boolean;
    useDownloadIcon?: boolean;
}

interface IState {
    externalFile?: any;
    isLoadingExternalFile?: boolean;
    externalFileFetchFailed?: boolean;
    hideExternalFileImage?: boolean;
    displayShareModal?: boolean;
    localStorageHasItem: boolean;
}

class ExternalFile extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        const localStorageHasItem = this.localStorageHasItem();
        const externalFile = props.externalFile ? props.externalFile : localStorageHasItem ? this.getItemFromLocalStorage() : undefined;

        this.state = {
            isLoadingExternalFile: !localStorageHasItem && !externalFile,
            externalFileFetchFailed: localStorageHasItem && !externalFile,
            externalFile,
            localStorageHasItem
        };
    }

    localStorageHasItem() {
        const items = localStorage.getItem("spintr_external_files");
        const parsedItems = items ? JSON.parse(items) : {};

        return parsedItems.hasOwnProperty(this.props.externalId);
    }

    getItemFromLocalStorage() {
        const items = localStorage.getItem("spintr_external_files");
        const parsedItems = items ? JSON.parse(items) : {};

        if (!parsedItems.hasOwnProperty(this.props.externalId)) {
            return null;
        }

        return parsedItems[this.props.externalId];
    }

    setItemInLocalStorage(item) {
        let items = localStorage.getItem("spintr_external_files");
        let parsedItems = items ? JSON.parse(items) : {};

        parsedItems[this.props.externalId] = item;

        localStorage.setItem("spintr_external_files", JSON.stringify(parsedItems));
    }

    componentDidMount() {
        if (this.props.externalFile) {
            return;
        }

        const source = parseInt(this.props.source, 10);
        if (source === SpintrTypes.FolderRootSource.GoogleDrive) {
            api
                .get<Spintr.GoogleDriveItem>(`/api/files/google/${this.props.externalId}`, { params: { source }})
                .then((response) => this.setState({
                    externalFile: {
                        ...response.data,
                        createdBy: response.data.lastModifyingUser,
                        webUrl: response.data.webViewLink,
                        downloadUrl: response.data.webContentLink,
                    },
                }))
                .catch((_) => this.setState({
            }));
            return;
        }

        if (!this.state.externalFile && !this.state.localStorageHasItem && !this.props.externalFile) {
            const url = "/api/files/office365/" +
                this.props.externalId +
                "?source=" +
                this.props.source;

            api.get(url).then((response) => {
                this.setItemInLocalStorage(response.data);

                this.setState({
                    isLoadingExternalFile: false,
                    externalFile: response.data
                });
            }).catch(() => {
                this.setItemInLocalStorage(null);
                this.setState({
                    isLoadingExternalFile: false,
                    externalFileFetchFailed: true
                });
            });
            return;
        }
    }

    openFile() {
        if (this.props.externalFile &&
            this.props.externalFile.onOpen) {
            return this.props.externalFile.onOpen();
        }

        window.open(this.state.externalFile.webUrl, '_blank');
    }

    protected renderItem(item): ReactNode {
        const key = `o356sidebar.office-file.${item.id}`;
        const iconSrc = getFileIconUrl(item);
        const imageSrc = item.thumbnailMedium ?? item.previewImage;

        const displayThumbnail = (
            imageSrc &&
            imageSrc.length > 0 &&
            !this.state.hideExternalFileImage
        );

        return (
            <div
                role="link"
                className="office-file"
                data-id={item.id}
                key={key}
                onClick={this.openFile.bind(this)}
            >
                <div className="external-file">
                    {displayThumbnail && (
                        <div className="image-preview">
                            <img
                                alt=""
                                data-id={item.id}
                                src={imageSrc}
                                onError={() => {
                                    this.setState({
                                        hideExternalFileImage: true
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="details">
                    <div className="file-name">
                        <img src={iconSrc} alt="" />
                        <Label className="text" size="body-2" color="dark-grey">
                            {item.name}
                        </Label>
                    </div>
                    {(item.createdBy?.displayName || item.owner?.name) && (
                        <Label className="author" size="small-1">
                            {item.createdBy?.displayName || item.owner?.name}
                        </Label>
                    )}
                    <div className="date">
                        <Label size="small-1">
                            {moment(item.created ? item.created : item.createdDateTime).format("YYYY-MM-DD")}
                        </Label>
                    </div>
                </div>
            </div>
        );
    }

    getActions() {
        let actions = [];

        if (this.props.hideActions) {
            return actions;
        }

        if (!this.props.hideShare) {
            actions.push({
                icon: "share",
                title: localize("Dela"),
                onClick: () => {
                    this.setState({
                        displayShareModal: true
                    });
                }
            });
        }

        if (this.props.useDownloadIcon) {
            actions.push({
                icon: "arrow-down-2",
                title: localize("LaddaNed"),
                onClick: this.openFile.bind(this)
            });
        } else {
            actions.push({
                icon: "send-1",
                title: localize("Oppna"),
                onClick: this.openFile.bind(this)
            });
        }

        if (this.props.externalFile &&
            this.props.externalFile.onRemove) {
            actions.push({
                icon: "trash",
                title: localize("TaBort"),
                onClick: this.props.externalFile.onRemove
            });
        }

        return actions;
    }

    render() {
        if (this.state.isLoadingExternalFile) {
            return (
                <div className="ExternalFile-loader">
                    <Loader />
                </div>
            );
        }

        if (this.state.externalFileFetchFailed) {
            return (
                <div className="ExternalFile-error-message-wrapper">
                    <div className="spintr-list-empty-list">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                            {localize("FilenArBorttagenEllerSaHarduInteBehorighetAttSeDen")}
                        </Label>
                    </div>
                </div>
            );
        }

        if (!this.state.externalFile) {
            return null;
        }

        return (
            <div className="ExternalFile">
                <DivWithHoverActions actions={this.getActions()} displayActionsAtBottom={true}>
                    {
                        this.renderItem(this.state.externalFile)
                    }
                </DivWithHoverActions>
                {
                    this.state.displayShareModal ?
                        <ShareModal
                            objectId={this.props.externalId ?
                                this.props.externalId :
                                this.props.externalFile.id}
                            isExternalFile={true}
                            externalFileSource={this.props.source}
                            onDismiss={() => {
                                this.setState({
                                    displayShareModal: false
                                });
                            }}
                        /> :
                        null
                }
            </div>
        )
    }
}

export default withRouter(ExternalFile);
