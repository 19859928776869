import React, { Fragment, ReactElement, useCallback, useEffect } from "react";
import { PlannerItemDetailDrawerProps } from "./PlannerItemDetailDrawer.types";
import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from "src/components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Conditional } from "src/components/Conditional";
import SpintrLoader from "src/ui/components/Loader";
import { getCancelTokenSource } from "src/api";
import { deletePlannerItemAsync, getPlannerItemAsync } from "src/api/plannerApi";
import { plannerItemCreated } from "src/redux";
import { UnstyledButton } from "src/ui";
import { localize } from "src/l10n";
import { Icon } from "@fluentui/react";
import { PlannerItemDetail } from "../PlannerItemDetail";
import { setConfirmPopup } from "src/popups/actions";

function PlannerItemDetailDrawer(props: PlannerItemDetailDrawerProps): ReactElement {
    const { open, onEditClick, onDismiss, selectedId, size } = props;
    const dispatch = useDispatch();

    const item = useSelector<Spintr.AppState, Spintr.PlannerItem | undefined>(
        (appState) => !selectedId ? undefined : appState.planner.itemsById[selectedId],
    );

    const hasElevatedPermissions = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.profile.active.roles.some(
            (role) => role === "administrators" || role === "editor",
        ),
    );

    const onOpenChanged = useCallback(
        (open) => {
            if (open) {
                return;
            }
            
            onDismiss?.();
        },
        [onDismiss],
    );

    const onEditClicked = useCallback(() => {
        if (!selectedId) {
            return;
        }

        onEditClick?.(selectedId);
    }, [onEditClick, selectedId]);

    const onDeleteClicked = useCallback(() => {
        if (!item) {
            return;
        }

        dispatch(setConfirmPopup({
            isOpen: true,
            title: localize("VillDuVerkligenTaBortX").replace("{{X}}", item.name),
            onConfirm: async () => {
                try {
                    await deletePlannerItemAsync(item.id);
                } catch (err) {
                    console.error(err);
                    return;
                }

                onDismiss?.();
            },
        }));
    }, [item, onDismiss]);

    useEffect(() => {
        if (!selectedId || item) {
            // Maybe onDismiss should be called here
            return;
        }

        const cancelTokenSource = getCancelTokenSource();
        getPlannerItemAsync(selectedId, cancelTokenSource.token)
            .then((item) => dispatch(plannerItemCreated(item)))
            .catch((error) => {
                console.error(error);

                onDismiss?.();
            });

        return () => cancelTokenSource.cancel();
    }, [selectedId, item, onDismiss]);

    const headerActons = (
        <div className="actions">
            <Conditional condition={hasElevatedPermissions}>
                <UnstyledButton
                    className="edit-button"
                    title={localize("TaBort")}
                    onClick={onDeleteClicked}
                >
                    <Icon iconName="Delete" />
                </UnstyledButton>
            </Conditional>
            <Conditional condition={hasElevatedPermissions}>
                <UnstyledButton
                    className="edit-button"
                    title={localize("Redigera")}
                    onClick={onEditClicked}
                >
                    <Icon iconName="Edit" />
                </UnstyledButton>
            </Conditional>
            <UnstyledButton
                className="close-button"
                title={localize("Stang")}
                onClick={onDismiss}
            >
                <Icon iconName="ChromeClose" />
            </UnstyledButton>
        </div>
    );

    return (
        <Drawer
            className="PlannerItemDetailDrawer"
            onOpenChange={onOpenChanged}
            open={open}
            position="end"
            size={size ?? "medium"}
        >
            <Conditional condition={item !== undefined}>
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={headerActons}
                        className="PlannerItemDetailDrawer-header"
                        textProps={{ size: "bodyLarge", color: "black" }}
                    >
                        <div
                            className="PlannerItemDetailDrawer-header-circle"
                            style={{ backgroundColor: item?.color }} />
                        {item?.name}
                    </DrawerHeaderTitle>
                </DrawerHeader>
            </Conditional>

            <DrawerBody className="PlannerItemDetailDrawer-body">
                <Conditional condition={item === undefined}>
                    <SpintrLoader />
                </Conditional>

                <Conditional condition={item !== undefined}>
                    <PlannerItemDetail item={item} />
                </Conditional>
            </DrawerBody>
        </Drawer>
    )
}

export default PlannerItemDetailDrawer;
