import { SpintrTypes } from "src/typings";
import { ColumnDuration, PlannerBarGroup, PlannerTimelineItem, TimelineMode } from "./types";
import { localize } from "src/l10n";
import moment from "moment";

export const PlannerFileTypes = [
    "pdf", "doc", "docx", "odt", "ppt", "pptx", "xls", "xlsx"
];

export function getAvailableFileTypes(): string {
    return `${localize("VALID_FILE_TYPES")} ${PlannerFileTypes.join(", ")}`;
}

export function snapToGrid(date: Date, mode: TimelineMode): Date {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    if (mode === "DAYS") {
        date.setHours(0, 0, 0, 0);
    
        return date;
    }

    if (!(hours > 12 || (hours === 12 && (minutes > 0 || seconds > 0 || milliseconds > 0)))) {
        date.setDate(date.getDate() - 1);
    }
    date.setHours(23, 59, 59, 999);

    return date;
}

export function snapCreationBoxToGrid(date: Date, mode: TimelineMode): Date {
    if (mode === "DAYS") {
        date.setHours(0, 0, 0, 0);
    
        return date;
    }

    date.setHours(0, 0, 0, 0);

    return date;
}

export function getWeekStart(date: Date, weekStartsOn?: number | undefined): Date {
    const weekStart = weekStartsOn ?? 1;

    const day = date.getDay();
    const diff = (day < weekStart ? 7 : 0) + day - weekStart;

    const retVal = new Date(date.getTime());
    retVal.setDate(date.getDate() - diff);

    return retVal;
}

function getDurationStartDate(now: Date, mode: TimelineMode): Date {
    const start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

    switch (mode) {
        case "DAYS":
            start.setHours(0, 0, 0, 0);
            return start;

        default:
        case "WEEKS":
            return getWeekStart(start);          
            
        case "MONTHS":
            start.setDate(1);
            start.setHours(0, 0, 0, 0);
            return start;

        case "QUARTERS":
            const startMonth = start.getMonth() - (start.getMonth() % 3);
            start.setMonth(startMonth, 1);
            start.setHours(0, 0, 0, 0);
            return start;
    }
}

function getDurationEndDate(start: Date, mode: TimelineMode): Date {
    const end = new Date(start.getTime());
    end.setFullYear(start.getFullYear() + 3);

    switch (mode) {
        case "DAYS":
            end.setHours(23, 59, 59, 999);
            return end;

        default:
        case "WEEKS":
            const diff = end.getDay() === 0 ? 0 : 7 - end.getDay();
            end.setDate(end.getDate() + diff);
            end.setHours(23, 59, 59, 999);
            return end;

        case "MONTHS":
            end.setMonth(end.getMonth() + 1, 0);
            end.setHours(23, 59, 59, 999);
            return end;

        case "QUARTERS":
            const endMonth = end.getMonth() + 2 - (end.getMonth() % 3);
            end.setMonth(endMonth + 1, 0);
            end.setHours(23, 59, 59, 999);
            return end;
    }
}

export function getColumnDurations(start: Date, end: Date, mode: TimelineMode): ColumnDuration[] {
    const columns: ColumnDuration[] = [];

    const current = new Date(start.getTime());
    while (current.getTime() < end.getTime()) {
        let currentEnd: Date;

        switch (mode) {
            case "DAYS":
                currentEnd = new Date(current.getTime());
                currentEnd.setHours(23, 59, 59, 999);
                break;

            default:
            case "WEEKS":
                currentEnd = new Date(current.getTime());
                currentEnd.setDate(current.getDate() + 6);
                break;
            
            case "MONTHS":
                currentEnd = new Date(current.getTime());
                currentEnd.setMonth(current.getMonth() + 1, 0);
                break;

            case "QUARTERS":
                currentEnd = new Date(current.getTime());
                currentEnd.setMonth((current.getMonth() + 2 - (current.getMonth() % 3)) + 1, 0);
                break;
        }

        currentEnd.setHours(23, 59, 59, 999);

        if (currentEnd.getTime() > end.getTime()) {
            currentEnd = new Date(end.getTime());
        }

        columns.push({
            startMilliseconds: current.getTime(),
            totalMilliseconds: currentEnd.getTime() - current.getTime(),
            endMilliseconds: currentEnd.getTime(),
        });

        switch (mode) {
            case "DAYS":
                current.setDate(current.getDate() + 1);
                break;

            default:
            case "WEEKS":
                current.setDate(current.getDate() + 7);
                break;
            
            case "MONTHS":
                current.setMonth(current.getMonth() + 1);
                break;

            case "QUARTERS":
                const nextMonth = current.getMonth() + 3
                current.setMonth(nextMonth - (nextMonth % 3), 1);
                break;
        }
    }

    return columns;
}

export function generateColumnDurations(now: Date, mode: TimelineMode): ColumnDuration[] {
    const start = getDurationStartDate(now, mode);

    return getColumnDurations(
        start,
        getDurationEndDate(start, mode),
        mode
    );
}

export function getTimelineDuration(columns: ColumnDuration[]): ColumnDuration {
    return {
        startMilliseconds: columns[0].startMilliseconds,
        endMilliseconds: columns[columns.length - 1].endMilliseconds,
        totalMilliseconds: columns[columns.length - 1].endMilliseconds - columns[0].startMilliseconds,
    };
}

export function getPlannerItemTypeText(type: Spintr.PlannerItemType): string {
    switch (type) {
        default:
        case SpintrTypes.PlannerItemType.General:
            return localize("PLANNER_TYPE_GENERAL");

        case SpintrTypes.PlannerItemType.Campaign:
            return localize("PLANNER_TYPE_CAMPAIGN");

        case SpintrTypes.PlannerItemType.Course:
            return localize("PLANNER_TYPE_COURSE");

        case SpintrTypes.PlannerItemType.Delivery:
            return localize("PLANNER_TYPE_DELIVERY");

        case SpintrTypes.PlannerItemType.Event:
            return localize("PLANNER_TYPE_EVENT");

        case SpintrTypes.PlannerItemType.Inventory:
            return localize("PLANNER_TYPE_INVENTORY");

        case SpintrTypes.PlannerItemType.Maintenance:
            return localize("PLANNER_TYPE_MAINTENANCE");

        case SpintrTypes.PlannerItemType.Release:
            return localize("PLANNER_TYPE_RELEASE");
    }
}

export function getPlannerResourceTypeText(type: Spintr.PlannerItemResourceType): string | undefined {
    switch (type) {
        case SpintrTypes.PlannerItemResourceType.Equipment:
            return localize("PLANNER_RESOURCE_EQUIPMENT");

        case SpintrTypes.PlannerItemResourceType.Material:
            return localize("PLANNER_RESOURCE_MATERIAL");

        case SpintrTypes.PlannerItemResourceType.Personnel:
            return localize("PLANNER_RESOURCE_PERSONNEL");
            
        default:
            return undefined;
    }

}

export function getMarketingChannelTypeText(type: Spintr.PlannerCampaignMarketingChannel): string | undefined {
    switch (type) {
        case SpintrTypes.PlannerMarketingChannel.Email:
            return localize("PLANNER_CAMPAIGN_MARKETING_EMAIL");

        case SpintrTypes.PlannerMarketingChannel.SocialMedia:
            return localize("PLANNER_CAMPAIGN_MARKETING_SOCIAL");

        case SpintrTypes.PlannerMarketingChannel.Store:
            return localize("PLANNER_CAMPAIGN_MARKETING_STORE");

        default:
            return undefined;
    }
}

export function getInventoryMethodText(method: Spintr.PlannerInventoryMethod): string | undefined{
    switch (method) {
        case SpintrTypes.PlannerInventoryMethod.Manual:
            return localize("PLANNER_INVENTORY_METHOD_MANUAL");

        case SpintrTypes.PlannerInventoryMethod.Digital:
            return localize("PLANNER_INVENTORY_METHOD_DIGITAL");

        default:
            return undefined;
    }
}

class UnionFind<TKey = number | string> {
    private parent: Map<TKey, TKey>;

    constructor(keys: TKey[]) {
        this.parent = new Map();

        for (const key of keys) {
            this.parent.set(key, key);
        }
    }

    public find(key: TKey): TKey {
        if (this.parent.get(key) !== key) {
            this.parent.set(key, this.find(this.parent.get(key)!));
        }
        
        return this.parent.get(key)!;
    }

    public union(key1: TKey, key2: TKey) {
        const root1 = this.find(key1);
        const root2 = this.find(key2);

        if (root1 !== root2) {
            this.parent.set(root1, root2);
        }
    }
}

function findOverlappingGroups<TKey = number | string>(items: PlannerTimelineItem<TKey>[]): Map<TKey, number> {
    items.sort((a, b) => a.start.getTime() - b.start.getTime());

    const unionFind = new UnionFind<TKey>(items.map((item) => item.key));

    for (let i = 0; i < items.length; i++) {
        const outerItem  = items[i];

        for (let j = i + 1; j < items.length; j++) {
            const innerItem = items[j];

            if (innerItem.start.getTime() > outerItem.end.getTime()) {
                break;
            }

            if (outerItem.end.getTime() >= innerItem.start.getTime()) {
                unionFind.union(outerItem.key, innerItem.key);
            }
        }
    }

    const groupMap: Map<TKey, number> = new Map();
    const rootToGroupId: Map<TKey, number> = new Map();
    let groupId = 0;

    for (const item of items) {
        const root = unionFind.find(item.key);

        if (!rootToGroupId.has(root)) {
            rootToGroupId.set(root, groupId++);
        }

        groupMap.set(item.key, rootToGroupId.get(root)!);
    }

    return groupMap;
}

type TimelineEvent<TKey> = {
    time:   Date;
    type:   "start" | "end";
    item:   PlannerTimelineItem<TKey>;
}

function assignLayers<TKey>(
    items: PlannerTimelineItem<TKey>[],
    barHeight: number,
): PlannerBarGroup<TKey> {
    const events = items
        .flatMap<TimelineEvent<TKey>>((item) => [
            { time: item.start, type: "start", item, },
            { time: item.end, type: "end", item, },
        ])
        .sort((a, b) => a.time.getTime() !== b.time.getTime()
            ? a.time.getTime() - b.time.getTime()
            : (a.type === b.type ? 0 : a.type === "start" ? -1 : 1)
        );

    const availableLayers: number[] = [];
    const itemToLayer: Map<TKey, number> = new Map();
    const activeLayers: Set<number> = new Set();
    let maxLayerUsed = 0;

    for (const event of events) {
        if (event.type !== "start") {
            const layer = itemToLayer.get(event.item.key)!;
            activeLayers.delete(layer);
            availableLayers.push(layer);
            continue;
        }

        let layer: number;
        if (availableLayers.length > 0) {
            layer = availableLayers.pop()!;
        } else {
            layer = maxLayerUsed++;
        }

        itemToLayer.set(event.item.key, layer);
        activeLayers.add(layer);

        if (layer > maxLayerUsed) {
            maxLayerUsed = layer;
        }
    }

    const numberOfLayers = maxLayerUsed;
    const layerHeight = 1 / numberOfLayers;
    const adjustedBarHeight = barHeight - (numberOfLayers - 1) * 2

    return {
        key: items[0].key,
        items: items.map<PlannerTimelineItem<TKey>>((item) => {
            const layer = itemToLayer.get(item.key)!;

            return {
                ...item,
                layer,
                height: layerHeight * adjustedBarHeight,
                topOffset: 4 + layer * layerHeight * adjustedBarHeight + layer * 2,
            };
        }),
        layers: numberOfLayers,
    };
}

export function assignLayersToItems<TKey = string | number>(
    items: PlannerTimelineItem<TKey>[],
    barHeight: number = 42,
): PlannerBarGroup<TKey>[] {
    const groupMap = findOverlappingGroups<TKey>(items);
    
    const groups: Map<number, PlannerTimelineItem<TKey>[]> = new Map();
    for (const item of items) {
        const groupId = groupMap.get(item.key)!;

        if (!groups.has(groupId)) {
            groups.set(groupId, []);
        }

        groups.get(groupId)!.push(item);
    }

    const output: PlannerBarGroup<TKey>[] = [];
    const iterator = groups.entries();
    
    let iteratorResult: IteratorResult<[number, PlannerTimelineItem<TKey>[]]>;
    while ((iteratorResult = iterator.next()) && !iteratorResult.done) {
        const [_, group] = iteratorResult.value;

        output.push(assignLayers<TKey>(group, barHeight));
    }

    return output;
}

export function calculateBarPosition(
    timelineWidth: number,
    timelineDuration: ColumnDuration,
    start: number,
    end: number
): { left: number, right: number } {
    const timeSinceStart = start - timelineDuration.startMilliseconds;
    const left = (timeSinceStart / timelineDuration.totalMilliseconds) * timelineWidth;

    const timeUntilEnd = timelineDuration.endMilliseconds - end;
    const right = (timeUntilEnd / timelineDuration.totalMilliseconds) * timelineWidth;

    return { left, right};
}

export function formatDates(startMs: number, endMs: number): string {
    const startMoment = moment(startMs);
    const endMoment = moment(endMs);

    let retVal: string = startMoment.format("D");
    if (startMoment.isSame(endMoment, "day")) {
        return retVal + ` ${startMoment.format("MMM")}`;
    }

    if (startMoment.year() !== endMoment.year() || startMoment.month() !== endMoment.month()) {
        retVal += ` ${startMoment.format("MMM")}`;
    }

    retVal += ` - ${endMoment.format("D MMM")}`;

    return retVal;
}
