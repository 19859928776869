
import React, { MutableRefObject, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";
import "./CalypsoContentWithSidebar.scss";
import classNames from 'classnames';

type RefType = {
    toggleIsExpanded: () => string;
}

type Props = PropsWithChildren<{
    innerRef?:              MutableRefObject<RefType>;
    renderSideBar:          () => ReactElement;
    sidebarStartsExpanded?: boolean | undefined;
}>;

function CalypsoContentWithSidebar({
    children,
    innerRef,
    renderSideBar,
    sidebarStartsExpanded,
}: Props): ReactElement {
    const [isExpanded, setIsExpanded] = useState<boolean>(!!sidebarStartsExpanded);

    useEffect(() => {
        if (!innerRef) {
            return;
        }

        innerRef.current = {
            toggleIsExpanded() {
                setIsExpanded(!isExpanded);

                return isExpanded ? "sidebar-left" : "sidebar-right";
            },
        };
    }, [isExpanded]);

    const className = useMemo(() => classNames(
        "CalypsoContentWithSidebar",
        { "expanded": isExpanded },
    ), [isExpanded]);

    return (
        <div className={className}>
            <div className="content">
                {children}
            </div>
            <div className="sidebar">
                {renderSideBar()}
            </div>
        </div>
    )
};

export default CalypsoContentWithSidebar;
