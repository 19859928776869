import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Modal } from "@fluentui/react";
import React, {
    Component, ReactNode
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { addRemoteConversation, queryConversations } from "src/chat";
import { createConversation } from "src/chat/api";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, Loader, PageHeader, SpintrUser } from "src/ui";
import { ConversationItem } from "../ConversationItem";
import "./ConversationsPanel.scss";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import GroupInvitationNotification from "src/notifications/components/GroupInvitationNotification";
import moment from "moment";
import { circleLarge, circleMedium } from "src/ui/helpers/style";
import { VisageSidebarHeader } from "src/sidebar/components/VisageSidebar/VisageSidebarHeader";
import { VisageSidebarMode, setSidebarMode } from "src/sidebar";
import FilterButtons from "../new/FilterButtons/FilterButtons";

interface IStateProps {
    conversations: Spintr.IConversation[];
    isLoading?: boolean;
    hasMore?: boolean;
    hasLoadedConversations?: boolean;
    dispatch?: any;
    currentUser?: any;
    displayAsOfflineInChat: boolean;
    unreadEmails?: number;
    googleConnected: boolean;
    office365Connected: boolean;
    exchangeWebmailUrl?: string;
    emailEnabled: boolean;
    allowAdministratorsAccessToAllGroups: boolean;
    disableGroupCreation: boolean;
    conversationsSkip: number;
    groupsSkip: number;
    usersSkip: number;
    unreadCountPersonConversations: number;
    unreadCountGroupConversations: number;
    disablePersonChat: boolean;
    groupsEnabled: boolean;
}

interface OwnProps {
    fullscreen?: boolean;
    inSidebar?: boolean;
    calculateScrollHeight?: boolean;
    openChatTabOnClick?: boolean;
    onConversationClick?: any;
    hideBackButton?: boolean;
}

type Props = IStateProps & OwnProps & RouteComponentProps;

interface IState {
    isLoadingSearch: boolean;
    isSearching: boolean;
    searchItems: Spintr.IConversation[];
    filter: string;
    iconsKey: number;
    displayMembersForGroup?: any;
    activeTabId: number;
}

class ConversationsPanel extends Component<Props, IState> {
    private conversationsCancelTokenSource : CancelTokenSource;
    private groupsCancelTokenSource : CancelTokenSource;
    private intervalRef : NodeJS.Timeout;

    constructor(props: Props) {
        super(props);

        let activeTabId = props.groupsEnabled ? 0 : 1;

        if (props.groupsEnabled && !props.disablePersonChat) {
            activeTabId = window.location.pathname.indexOf("/groups/chat") === 0 ? 1 : 0;
        }

        this.state = {
            isLoadingSearch: false,
            isSearching: false,
            searchItems: [],
            filter: "",
            iconsKey: 1,
            activeTabId
        };

        this.onConversationClick = this.onConversationClick.bind(this);
    }

    public componentDidMount(): void {
        this.fetch();

        this.intervalRef = setInterval(this.scrollHeightCheck.bind(this), 1000);
    }

    public componentWillUnmount(): void {
        if (this.conversationsCancelTokenSource) {
            this.conversationsCancelTokenSource.cancel();
        }

        if (this.groupsCancelTokenSource) {
            this.groupsCancelTokenSource.cancel();
        }

        clearInterval(this.intervalRef);
    }

    scrollHeightCheck() {
        const scrollEl = document.querySelector(".infinite-scroll-component");

        if (scrollEl && this.props.hasMore && scrollEl.scrollHeight === scrollEl.clientHeight) {
            this.loadMore();
        }
    }

    loadMore() {
        if (this.props.isLoading) {
            return;
        }

        this.fetch(true);
    }
    
    fetch(isLoadMore?: boolean) {
        if (this.conversationsCancelTokenSource) {
            this.conversationsCancelTokenSource.cancel();
        }

        this.conversationsCancelTokenSource = Axios.CancelToken.source();

        this.props.dispatch(queryConversations({
            conversationsSkip: isLoadMore ? this.props.conversationsSkip : 0,
            groupsSkip: isLoadMore ? this.props.groupsSkip : 0,
            usersSkip: isLoadMore ? this.props.usersSkip : 0,
            isLoadMore,
            take: 40,
            searchText: this.state.filter
        }, this.conversationsCancelTokenSource.token));
    }

    public render(): ReactNode {
        const localStorageId = "spintr_disableChatSound";
        const disableChatSound = localStorage.getItem(localStorageId) === "true";

        const emptyPlaceholder = "CONVERSATIONS_EMPTY_PLACEHOLDER";

        const headline = "COLLABORATION";

        const showGroupCreateButton = !this.props.currentUser.isGroupUser;
        const headerHasContent = this.props.fullscreen || showGroupCreateButton;

        const allConversations = [...this.props.conversations].filter(x => (this.state.activeTabId === 0 && x.group) || (this.state.activeTabId === 1 && !x.group));
        const invites = allConversations.filter(x => x.group?.membershipStatus === SpintrTypes.GroupMembershipStatus.Invited);
        const pinned = allConversations.filter(x => x.isPinned);
        const conversations = allConversations.filter(x => !x.isPinned && x.group?.membershipStatus != SpintrTypes.GroupMembershipStatus.Invited && x.id);
        const groups = allConversations.filter(x => x.id === 0 && !!x.group);
        const users = allConversations.filter(x => x.id === 0 && !x.group);

        return (
            <div className={"ConversationsPanel" + (!headerHasContent ? " empty-header" : "")}>
                <div className="header-wrap">
                    <PageHeader
                        title={localize(headline)}
                        displaySearch
                        onSearchQueryChange={(value: string) => {
                            this.setState({
                                filter: value
                            }, () => {
                                this.fetch();
                            });
                        }}
                        buttons={[
                            ...(!this.props.disableGroupCreation ?
                                [
                                    {
                                        key: "create-group",
                                        title: localize("SkapaNyGrupp"),
                                        icon: "add",
                                        theme: "primary" as const,
                                        subMenuProps: {
                                            items: [
                                                ...((this.props.disableGroupCreation || !this.props.groupsEnabled) ? [] : [{
                                                    key: "0",
                                                    text: localize("SkapaNyGrupp"),
                                                    onClick: () => {
                                                        this.props.history.push({
                                                            pathname: "/groups/create",
                                                        });
                                                    },
                                                }]),
                                                ...(this.props.disablePersonChat ? [] : [{
                                                    key: "1",
                                                    text: localize("StartaEnNyKonversation"),
                                                    onClick: () => {
                                                        this.props.history.push({
                                                            pathname: "/groups/chat/new",
                                                        });
                                                    },
                                                }])
                                            ]
                                        }
                                    },
                                ]
                                : []),
                        ]} />
                </div>
                {(this.props.groupsEnabled && !this.props.disablePersonChat) && (
                    <FilterButtons
                        tabs={[
                            ...(this.props.groupsEnabled ?
                                [{
                                    id: 0,
                                    text: localize("Grupper"),
                                    unreadCount: this.props.conversations.filter(x => x.group && x.unread > 0).length ||
                                        this.props.unreadCountGroupConversations
                                }] :
                                []),
                            ...(this.props.disablePersonChat ?
                                [] :
                                [{
                                    id: 1,
                                    text: localize("PersonerStor"),
                                    unreadCount: this.props.conversations.filter(x => !x.group && x.unread > 0).length ||
                                        this.props.unreadCountPersonConversations
                                }]),
                        ]}
                        initialActiveId={this.state.activeTabId}
                        onChange={(id: number) => {
                            this.setState({
                                activeTabId: id
                            });
                        }} />
                )}
                <div className="list-wrapper">
                    <InfiniteScroll
                        ref={"InfiniteScroll"}
                        dataLength={this.props.conversations.length}
                        next={this.loadMore.bind(this)}
                        hasMore={this.props.hasMore}
                        loader={<Loader />}
                        height={"100%"}>
                        {
                            (!this.props.conversations || this.props.conversations.length === 0) && this.props.isLoading && (
                                <Loader />
                            )
                        }
                        {
                            (!allConversations || allConversations.length === 0) && !this.props.isLoading && !this.props.hasMore && (
                                <div className="empty-sidebar-list">
                                    <div className="icon">
                                        <Visage2Icon icon="coffee" />
                                    </div>
                                    <div className="content">
                                        <Label role="text" size="body-2">
                                            <span>
                                                {localize(emptyPlaceholder)}
                                            </span>
                                        </Label>
                                    </div>
                                </div>
                            )
                        }
                        {
                            !!this.props.conversations &&
                            this.props.conversations.length > 0 && (
                                <>
                                    {invites.length > 0 && (
                                        <div className="ConversationsPanel-segment">
                                            <Label
                                                as="div"
                                                className="ConversationsPanel-subline"
                                                size="small-1"
                                                color="primaryContent"
                                                weight="medium">
                                                <Visage2Icon icon="profile-add" color="primaryContent" type="bold" size="small" />
                                                {localize("INVITES")}
                                            </Label>
                                            {invites.map((conversation) => (
                                                <div
                                                    className="ConversationItem invite"
                                                    key={conversation.id}
                                                >
                                                    <div className="image">
                                                        <SpintrUser
                                                            name={conversation.group?.inviteUserName}
                                                            imageUrl={conversation.group?.inviteUserImageUrl}
                                                            hideText
                                                            personalName
                                                            size={circleMedium}
                                                        />
                                                    </div>
                                                    <GroupInvitationNotification
                                                        notification={
                                                            {
                                                                id: 0,
                                                                objectId: 0,
                                                                imageUrl: "",
                                                                date: new Date(),
                                                                isRead: true,
                                                                isVideo: false,
                                                                type: 1,
                                                                url: "groups/" + conversation.group?.id,
                                                                groupName: conversation.group?.name,
                                                                userName: conversation.group?.inviteUserName,
                                                                userId: conversation.group?.inviteUserId,
                                                                inviteId: conversation.group?.membershipId,
                                                            } as Spintr.IGroupInvitationNotification
                                                        }
                                                        history={this.props.history}
                                                        dispatch={this.props.dispatch}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {pinned.length > 0 && (
                                        <div className="ConversationsPanel-segment">
                                            <Label
                                                as="div"
                                                className="ConversationsPanel-subline"
                                                size="small-1"
                                                color="primaryContent"
                                                weight="medium">
                                                <Visage2Icon icon="pin" color="primaryContent" type="custom" size="small" />
                                                {this.state.activeTabId === 0 ? localize("ATTACHED_GROUPS") : localize("ATTACHED_CONVERSATIONS")}
                                            </Label>
                                            {pinned.map((conversation, index: number) => (
                                                <div
                                                    className="conversation"
                                                    key={conversation.id + "_" + index}
                                                >
                                                    <ConversationItem
                                                        isGroupItem
                                                        isPinned
                                                        conversation={conversation}
                                                        currentUserId={this.props.currentUser.id}
                                                        onClick={this.onConversationClick}
                                                        location={this.props.location}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {conversations.length > 0 && (
                                        <div className="ConversationsPanel-segment">
                                            <Label
                                                as="div"
                                                className="ConversationsPanel-subline"
                                                size="small-1"
                                                color="primaryContent"
                                                weight="medium">
                                                <Visage2Icon icon="profile-2user" color="primaryContent" size="small" type="bold" />
                                                {this.state.activeTabId === 0 ? localize("Grupper") : localize("CONVERSATIONS")}
                                            </Label>
                                            {conversations.map((conversation, index: number) => (
                                                <div
                                                    className="conversation"
                                                    key={conversation.id + "_" + index}
                                                >
                                                    <ConversationItem
                                                        isGroupItem={!!conversation.group}
                                                        conversation={conversation}
                                                        currentUserId={this.props.currentUser.id}
                                                        onClick={this.onConversationClick}
                                                        location={this.props.location}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {groups.length > 0 && (
                                        <div className="ConversationsPanel-segment">
                                            <Label
                                                as="div"
                                                className="ConversationsPanel-subline"
                                                size="small-1"
                                                color="primaryContent"
                                                weight="medium">
                                                <Visage2Icon icon="profile-2user" color="primaryContent" size="small" type="bold" />
                                                {localize("AllaGrupper")}
                                            </Label>
                                            {groups.map((conversation, index: number) => (
                                                <div
                                                    className="conversation"
                                                    key={conversation.id + "_" + index}
                                                >
                                                    <ConversationItem
                                                        isGroupItem
                                                        conversation={conversation}
                                                        currentUserId={this.props.currentUser.id}
                                                        onClick={this.onConversationClick}
                                                        location={this.props.location}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {users.length > 0 && (
                                        <div className="ConversationsPanel-segment">
                                            <Label
                                                as="div"
                                                className="ConversationsPanel-subline"
                                                size="small-1"
                                                color="primaryContent"
                                                weight="medium">
                                                <Visage2Icon icon="personalcard" color="primaryContent" size="small" type="bold" />
                                                {localize("ALL_COWORKERS")}
                                            </Label>
                                            {users.map((conversation, index: number) => (
                                                <div
                                                    className="conversation"
                                                    key={conversation.id + "_" + index}
                                                >
                                                    <ConversationItem
                                                        conversation={conversation}
                                                        currentUserId={this.props.currentUser.id}
                                                        onClick={this.onConversationClick}
                                                        location={this.props.location}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )
                        }
                    </InfiniteScroll>
                </div>
                {
                    !!this.state.displayMembersForGroup ?
                        this.renderMembersModal() :
                        null
                }
            </div>
        );
    }

    closeMembersModal = () => {
        this.setState({
            displayMembersForGroup: null
        });
    }

    renderMembersModal() {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={!!this.state.displayMembersForGroup}
                onDismiss={this.closeMembersModal}
                containerClassName={"GroupList-MembersModal"}
            >
                <PopupHeader
                    text={localize("MedlemmarI") + " " + this.state.displayMembersForGroup.name}
                    onClose={this.closeMembersModal} />
                <SpintrList
                    // ref={this.membersListRef}
                    disableSearch={true}
                    disableCommandBar={true}
                    hideHeader={true}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api.get(`/api/v1/groupmembers`, {
                                params: {
                                    groupId: this.state.displayMembersForGroup.id,
                                    isAscending: isAscending,
                                    orderByColumn: "instanceId",
                                    phrase: searchQuery,
                                    take: take,
                                    skip: skip,
                                },
                            }).then((response: AxiosResponse) => {
                                resolve({
                                    data: response.data.members,
                                    totalCount: response.data.total
                                });
                            });
                        });
                    }}
                    columns={[
                        {
                            key: 5,
                            onRender: (item) => {
                                let subText = item.instanceId === 1 ?
                                    localize("Administrator") :
                                    item.instanceId === 2 ?
                                        localize("Medlem") :
                                        localize("Extern");

                                return <SpintrUser
                                    imageUrl={item.imageUrl}
                                    subText={subText}
                                    name={item.name}
                                    personalName={true} />;
                            },
                        }
                    ]}
                    orderByColumn={"name"}
                />
            </Modal>
        )
    }

    protected onConversationClick(conversation: Spintr.IConversation): void {
        if (!!conversation.group) {
            this.props.history.push("/groups/" + conversation.group.id);
            return;
        }

        if (conversation.id === 0) {
            createConversation(conversation.participants).then((response) => {
                this.props.dispatch(addRemoteConversation(response.data));
                this.props.history.push("/groups/chat/" + response.data.id);
            }).catch(() => { });

            return;
        }

        this.props.history.push("/groups/chat/" + conversation.id);
    }
};

const mapStateToProps: MapStateToProps<IStateProps, OwnProps, Spintr.AppState> =
    (state, props) => ({
        isLoading: state.chat.conversations.isLoading,
        hasMore: state.chat.conversations.hasMore,
        currentUser: state.profile.active,
        conversations: state.chat.conversations.items
            .filter(x => !x.isHidden)
            .sort(
                (a, b) => {
                    const aDate = !a.lastMessage ? moment().set("year", 1970).toDate() : typeof a.lastMessage.date === "string"
                        ? new Date(a.lastMessage.date)
                        : a.lastMessage.date;

                    const bDate = !b.lastMessage ? moment().set("year", 1970).toDate() : typeof b.lastMessage.date === "string"
                        ? new Date(b.lastMessage.date)
                        : b.lastMessage.date;

                    return (bDate.getTime() - aDate.getTime()) || (a.title.localeCompare(b.title));
                }
            ),
        hasLoadedConversations: state.chat.conversations.hasFetched,
        displayAsOfflineInChat: state.ui.displayAsOfflineInChat,
        unreadEmails: state.profile.unreadEmails,
        googleConnected: state.profile.active.googleConnected,
        office365Connected: state.profile.active.office365Connected,
        exchangeWebmailUrl: state.instance.get("exchangeWebmailUrl"),
        disableGroupCreation: state.instance.get("disableGroupCreation") || !state.profile.active.rights.hasAccessToGroups || (state.instance.get("restrictGroups") && !state.profile.active.isAdmin && !state.profile.active.isEditor && !state.profile.active.settings.canCreateGroups),
        emailEnabled:
            state.instance.get("enableExternalMailLink") != "false" &&
            (state.profile.active.googleConnected ||
                (state.profile.active.roles.includes("aduser") &&
                    (state.instance.get("enableExchange") ||
                        (state.profile.active.office365Connected && state.instance.get("office365Enabled"))))),
        allowAdministratorsAccessToAllGroups: state.profile.active.isSpintrAdminAccount ||
            (state.instance.get("allowAdministratorsAccessToAllGroups") && state.profile.active.isAdmin),
        conversationsSkip: state.chat.conversations.conversationsSkip,
        groupsSkip: state.chat.conversations.groupsSkip,
        usersSkip: state.chat.conversations.usersSkip,
        unreadCountGroupConversations: state.chat.conversations.unreadCountGroupConversations,
        unreadCountPersonConversations: state.chat.conversations.unreadCountPersonConversations,
        disablePersonChat: state.instance.get('disablePersonChat'),
        groupsEnabled: state.profile.active.rights.hasAccessToGroups,
    });

const ConversationsPanelWithRouter = withRouter(ConversationsPanel);

const ConnectedConversationsPanelWithRouter =
    connect(mapStateToProps)(ConversationsPanelWithRouter);

export default ConnectedConversationsPanelWithRouter;