import React, { ReactElement, useMemo } from "react";
import "./SystemStatusResourceInfo.scss";
import { Label, SpintrUser } from "src/ui";
import SystemStatusTypePill from "./SystemStatusTypePill";
import getOpenTime from "./utils";

type Props = {
    resource: Spintr.ISystemStatusResource;
}

function SystemStatusResourceInfo({ resource }: Props): ReactElement{
    const subText = useMemo(() => {
        // TODO: Return current status time

        return getOpenTime(resource.openTimes);
    }, [resource.openTimes]);

    return (
        <div className="SystemStatusResourceInfo">
            <SpintrUser
                key={resource.id}
                personalName={false}
                name={resource.title}
                subText={subText}
                imageUrl={resource.imageUrl}
                onRenderPrimaryText={() => {
                    return (
                        <div className="title-row">
                            <Label title={resource.title}>{resource.title}</Label>
                            <SystemStatusTypePill
                                statusType={resource.status} />
                        </div>
                    )
                }}
            />
        </div>
    );
}

export default SystemStatusResourceInfo;
