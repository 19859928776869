import React, { Component, ReactNode } from "react";
import SocialBlock from "src/interactions/components/SocialBlock";
import { SocialFeedType } from "src/social-feed";
import { SocialPost } from "../SocialPost";
import "./SocialPostContainer.scss";
import { SpintrTypes } from "src/typings";
import classNames from "classnames";
import { InteractiveTextFormatOptions } from "src/utils";
import { Label, UnstyledButton } from "src/ui";
import SystemStatusSocialPostHeader from "src/system-status/SystemStatusSocialPostHeader";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface ISocialPostContainerProps {
    identity?: any;
    isShare?: boolean;
    post: Spintr.ISocialPostBase;
    feedType?: SocialFeedType;
    onDoneEditing?: any;
    interactiveTextOptions?:    InteractiveTextFormatOptions | undefined;
}

interface IState {
    expanded: boolean;
}

class SocialPostContainer extends Component<ISocialPostContainerProps, IState> {
    constructor (props: ISocialPostContainerProps) {
        super(props);
        
        this.state = {
            expanded: this.props.feedType !== SocialFeedType.SystemStatus 
        }
    }

    public render(): ReactNode {
        const systemStatusPost = this.props.post.content.find((c) => c.Type === SpintrTypes.UberType.SystemStatus) as Spintr.ISocialSystemStatus;
        const isSystemStatus = !!systemStatusPost;
        const isSystemStatusFeed = this.props.feedType === SocialFeedType.SystemStatus;

        if (isSystemStatus &&
            isSystemStatusFeed &&
            !this.state.expanded &&
            systemStatusPost?.systemStatusType !== SpintrTypes.SystemStatusType.Ongoing &&
            systemStatusPost?.systemStatusType !== SpintrTypes.SystemStatusType.Planned) {
            return (
                <UnstyledButton
                    className={classNames("SocialPostContainer expandable-feed-post visage-box-shadow start-page-flexible-padding",
                        {
                            "border": this.props.feedType === SocialFeedType.SystemStatus,
                            "is-system-status": isSystemStatus,
                        })}
                    onClick={() => {
                        this.setState({
                            expanded: true
                        });
                    }}>
                    <SystemStatusSocialPostHeader post={this.props.post} />
                    <div className="spacer" />
                    <Label size="body-2" color="grey">{localize("SHOW_UPDATE")}</Label>
                    <Visage2Icon icon="arrow-down-1" color="grey" size="small" />
                </UnstyledButton>
            )
        }

        let commentPlaceholder: string | undefined;
        if (isSystemStatus) {
            commentPlaceholder = localize("COMPOSER_QUESTION");
        }

        return (
            <div className={classNames("SocialPostContainer visage-box-shadow start-page-flexible-padding",
            {
                "border": this.props.feedType === SocialFeedType.SystemStatus,
                "is-system-status": isSystemStatus,
            })}>
                <div className="post-wrapper">
                    <SocialPost
                        post={this.props.post}
                        onDoneEditing={this.props.onDoneEditing}
                        feedType={this.props.feedType}
                        interactiveTextOptions={this.props.interactiveTextOptions}
                    />
                </div>
                {!this.props.isShare &&
                    !this.props.post.hidden &&
                    (this.props.post.StatusType !== SpintrTypes.StatusType.SystemStatus ||
                        this.props.feedType === SocialFeedType.SystemStatus ||
                        this.props.feedType === undefined) && (
                            <SocialBlock
                                onCommentIconClick={() => { }}
                                commentPlaceholder={commentPlaceholder}
                                uberId={this.props.post.Id}
                                comments={this.props.post.comments}
                                likers={this.props.post.likers}
                                identity={this.props.identity}
                                sendToMixpanel={this.props.feedType === SocialFeedType.Combined}
                                enableReactions={true}
                                displayBigButtons={true}
                                isFeedPost={true}
                                targetCreatorId={this.props.post.UserId}
                                targetIsQuestion={this.props.post.StatusType === SpintrTypes.StatusType.Question}
                            />
                )}
            </div>
        );
    }
}

export default SocialPostContainer;