import { AxiosResponse } from "axios";
import React, { Component, ReactNode } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createConversation } from "src/chat/api";
import { addRemoteConversation, AddRemoteConversationHandler } from "src/chat/redux";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { FillHeight, Loader } from "src/ui";
import { TokenizedObjectInput } from "src/ui/components/Forms";
import ITokenizedInputItem from "src/ui/components/Forms/ITokenizedInputItem";
import "./NewConversationView.scss";
import Chat from "src/chat/components/new/Chat/Chat";
import { Style } from "src/ui/helpers";
import { setSidebarMode, VisageSidebarMode, setSidebarDrawerId, VisageSidebarDrawerId } from "src/sidebar";

interface IStateProps {
    currentUser: Spintr.IUser;
    dispatch?: any;
}

interface IState {
    conversation?: Spintr.IConversation;
    isLoading: boolean;
    items: ITokenizedInputItem[];
}

type Props =  IStateProps & RouteComponentProps;

class NewConversationView extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            items: [],
        };

        this.onCreateConversationSuccess = this.onCreateConversationSuccess.bind(this);
        this.onMessageSent = this.onMessageSent.bind(this);
        this.onRecipientsChange = this.onRecipientsChange.bind(this);
    }

    componentDidMount(): void {
        console.log(this.props.dispatch);
        this.props.dispatch(setSidebarMode(VisageSidebarMode.drawer));
        this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.groups));
    }

    public render(): ReactNode {
        return (
            <div id="NewConversationView" className="ignore-dirty-elements">
                <div className="header">
                    <TokenizedObjectInput
                        items={this.state.items}
                        onChange={this.onRecipientsChange}
                        placeholder={localize("chat_note") + "..."}
                        types={[SpintrTypes.UberType.User]}
                        excludeId={this.props.currentUser.id}
                    />
                </div>
                <div className="body">
                    <FillHeight bottomOffset={Style.getSpacing(4)}>
                        {this.state.isLoading && (
                            <Loader />
                        )}
                        {this.state.conversation && !this.state.isLoading && (
                            <Chat conversationId={this.state.conversation.id} onMessageSent={this.onMessageSent} />
                        )}
                    </FillHeight>
                </div>
            </div>
        );
    }

    protected onCreateConversationSuccess(response: AxiosResponse<Spintr.IConversation>): void {
        this.setState({  
            conversation: response.data,
            isLoading: false,
        });
    }

    protected onMessageSent(message: Spintr.IChatMessage): void {
        const url = "/groups/chat/" + message.conversationId;

        this.props.dispatch(addRemoteConversation(this.state.conversation));
        this.props.history.push(url);
    }

    protected onRecipientsChange(items: ITokenizedInputItem[]): void {
        this.setState(
            { conversation: null, isLoading: items.length > 0, items, },
            () => {
                if (items.length === 0) {
                    return;
                }

                const recipients: Spintr.IUser[] = this.state.items.map(
                    (item) => ({
                        id: parseInt(item.key, 10),
                        imageUrl: null,
                        info: null,
                        name: null,
                        type: null,
                        typeName: null,
                    }),
                );

                createConversation(recipients).then((response: AxiosResponse) => {
                    this.onCreateConversationSuccess(response);
                }).catch(() => {})
            },
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, {}, Spintr.AppState> =
    (state) => ({
        currentUser: {
            id: state.profile.active.id,
            imageUrl: state.profile.active.images.Message,
            info: "",
            name: state.profile.active.name,
            type: 1,
            typeName: "",
        },
    });

const NewConversationViewWithRouter = withRouter(NewConversationView);

const ConnectedNewConversationViewWithRouter =
    connect(mapStateToProps)(NewConversationViewWithRouter);

export default ConnectedNewConversationViewWithRouter;
