import { TooltipHost } from "@fluentui/react";
import classNames from "classnames";
import React, { Component, ReactNode, RefObject } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConversationsPanel } from "src/chat/components/ConversationsPanel";
import { localize } from "src/l10n";
import { NotificationList } from "src/notifications/components";
import ShortcutsPopupView from "src/shortcuts/ShortcutsPopupView";
import Color from "src/style/colors/Color";
import { SpintrTypes } from "src/typings";
import { Label, Scrollable, setDisplayAsOfflineInChat, SpintrCoachmark, UnstyledButton } from "src/ui";
import CreateWizard from "src/ui/components/CreateWizard";
import ContentWithUnreadIndicator from "src/ui/components/UnreadIndicator/ContentWithUnreadIndicator";
import { Style } from "src/ui/helpers";
import { mixpanelTrack } from "src/utils";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { startDeliverTrack } from "src/utils/spintrStartDeliverfunctions";
import { IApplicationState } from "../reducer";
import "./SpintrStaticLinks.scss";
import SpintrStaticLinksPopout from "./SpintrStaticLinksPopout";
import SpintrUserMenu from "./SpintrUserMenu";
import { markAllNotificationsAsRead } from "src/notifications/actions";
import api from "../SpintrApi";
import { clearUnreadEmails } from "src/profile/actions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import DrawerPopoutView from "./DrawerPopoutView";
import HeaderImportantArticlesButton from "./HeaderImportantArticlesButton/HeaderImportantArticlesButton";
import OperatingInfoNotification from "./OperatingInfoNotification";
import RightColumnDashboard from "src/admin/views/AdminDashboard/RightColumnDashboard";
import classnames from "classnames";

interface State {
    showCreateWizard: boolean;
    showShortcuts: boolean;
    showNotifications: boolean;
    showChat: boolean;
    conversationsIconsKey: number;
    shortcutsTitle: string;
    shortcutsEditMode: boolean;
}

interface Props extends RouteComponentProps {
    primaryFGColor: string;
    accentFGColor: string;
    instance?: any;
    isAdmin: boolean;
    isEditor: boolean;
    dispatch?: any;
    profile?: any;
    domain?: any;
    notificationsUnreadCount?: number;
    unreadCountPersonConversations?: number;
    currentUser?: any;
    disablePersonChat?: boolean;
    runIntroduction?: any;
    displayAsOfflineInChat: boolean;
    emailEnabled: boolean;
    googleConnected: boolean;
    office365Connected: boolean;
    exchangeWebmailUrl: string;
    unreadEmails?: number;
    isVisible?: boolean;
    disableCoachmarks: boolean;
    operatingInfoEnabled?: boolean;
    dashboardEnabled?: boolean;
    displayDashboardObjectsInHeader?: boolean;
    importantArticles?: any[];
}

class SpintrStaticLinks extends Component<Props, State> {
    private shortcutsButtonRef = React.createRef<HTMLDivElement>();
    protected chatIconAnimationRef?: any;
    protected notificationIconAnimationRef?: any;

    displayOfficeShortcuts =
        this.props.currentUser.office365Connected &&
        this.props.instance.get("office365Enabled") &&
        !this.props.instance.get("hideOffice365Shortcuts");

    displayTeams =
        this.props.currentUser.office365Connected &&
        this.props.instance.get("office365Enabled") &&
        this.props.instance.get("teamsTabEnabled") &&
        !this.props.currentUser.isGroupUser;

    constructor(props: Props) {
        super(props);

        this.state = {
            showCreateWizard: false,
            showShortcuts: false,
            showNotifications: false,
            showChat: false,
            conversationsIconsKey: 1,
            shortcutsTitle: localize("Genvagar"),
            shortcutsEditMode: false,
        };
    }

    hidePopouts() {
        this.setState({
            showNotifications: false,
            showChat: false,
            showCreateWizard: false,
            showShortcuts: false,
            shortcutsEditMode: false
        });
    }

    formatColor = (color: string) => {
        if (!color) {
            return "#EAEAEA";
        }

        if (color.length === 4) {
            color += color.substring(1);
        }

        return color.toUpperCase();
    };

    public setShortcutsTitle = (shortcutsTitle) => {
        this.setState({ shortcutsTitle })
    }

    public setShortcutsEditMode = (shortcutsEditMode) => {
        this.setState({ shortcutsEditMode })
    }

    private getFontColor(): spintrColors {
        let headerColor = this.props.instance.get("useColorHeader") ? (this.props.instance.get("headerColor") ?? "#FFFFFF") : "#FFFFFF";

        if (headerColor === "#FFFFFF") {
            return "primaryContent";
        }

        //@ts-ignore
        return getLightOrDarkColorBasedOnColor(headerColor, "light-grey", "dark-grey");
    }

    public render(): ReactNode {
        const fontColor = this.getFontColor();

        return (
            <div className="SpintrStaticLinks">
                {this.state.showShortcuts && (
                    <DrawerPopoutView
                        headerText={this.state.shortcutsTitle}
                        onDismiss={this.hidePopouts.bind(this)}
                        location={this.props.location}>
                                <ShortcutsPopupView
                                    extraWide={!this.state.shortcutsEditMode && (this.displayOfficeShortcuts || this.displayTeams)}
                                    setTitle={this.setShortcutsTitle}
                                    setEditMode={this.setShortcutsEditMode} />
                    </DrawerPopoutView>
                )} 
                <UnstyledButton
                    ariaLabel={localize("Genvagar")}
                    onClick={() => {
                        startDeliverTrack(SpintrTypes.InsightsType.OpenedShortcuts);
                        this.setState({
                            showShortcuts: !this.state.showShortcuts,
                        });

                        if (this.props.location.pathname === "/" && !this.state.showShortcuts) {
                            mixpanelTrack("Opened shortcuts");
                        }
                    }}
                    title={localize("Genvagar")}
                >
                    <div
                        className={`shortcuts-link link circle header-gradient-border no-user-select svg-animation icon-animation animation-pulse`}
                        id="shortcuts-link"
                        onMouseOver={() => {
                            if (!!this.chatIconAnimationRef) {
                                this.chatIconAnimationRef?.play();
                            }
                        }}
                    >
                        <Visage2Icon
                            icon="category-2"
                            color={fontColor}
                            //size="big"
                            />
                    </div>
                </UnstyledButton>
                <UnstyledButton
                    ariaLabel={localize("Notiser")}
                    onClick={() => {
                        this.setState({
                            showNotifications: true,
                        });
                    }}
                >
                    <div
                        className={`notifications-link link circle header-gradient-border no-user-select svg-animation icon-animation animation-pulse`}
                        id="notifications-link"
                        onMouseOver={() => {
                            if (!!this.notificationIconAnimationRef) {
                                this.notificationIconAnimationRef?.play();
                            }
                        }}
                        title={localize("Notiser")}
                    >
                        <ContentWithUnreadIndicator
                            margin={8}
                            active={this.state.showNotifications}
                            count={this.props.notificationsUnreadCount}
                            secondaryPlacement={true}
                        >
                            <Visage2Icon
                                icon="notification"
                                color={fontColor}
                                //size="big"
                            />
                        </ContentWithUnreadIndicator>
                    </div>
                </UnstyledButton>
                {this.state.showNotifications && (
                    <SpintrStaticLinksPopout
                        alternativePosition
                        headerText={localize("Notiser")}
                        menuItems={
                            {
                                items: [
                                    {
                                        text: localize("MarkeraAllSomLast"),
                                        onClick: () => {
                                            this.props.dispatch(markAllNotificationsAsRead());
                                        },
                                    },
                                ]
                            }
                        }
                        onDismiss={this.hidePopouts.bind(this)}
                        footerButtonText={localize("VisaAlla") + " " + localize("Notiser").toLowerCase()}
                        onFooterButtonClick={() => {
                            this.props.history.push({
                                pathname: "/notifications",
                            });
                        }}
                    >
                        <div className="PopoutFrame-content-inner">
                            <Scrollable>
                                <NotificationList />
                            </Scrollable>
                        </div>
                    </SpintrStaticLinksPopout>
                )}
                <UnstyledButton
                    ariaLabel={localize("LaggTillInnehall")}
                    className="add-button"
                    data-cy="add-content"
                    onClick={() => {
                        this.setState({ showCreateWizard: true });
                    }}
                    title={localize("LaggTillInnehall")}
                >
                    <div
                        className={classNames(`add-link link circle header-gradient-border no-user-select icon-animation animation-rotate`, {
                            "add-link-AdminOrEditor": this.props.isAdmin || this.props.isEditor,
                        })}
                        id="add-link"
                    >
                        <div className="gradient" />
                        <Visage2Icon
                            icon="add"
                            size="big"
                            color={fontColor}
                        />
                    </div>
                </UnstyledButton>
                {this.state.showCreateWizard && (
                    <SpintrStaticLinksPopout
                        alternativePosition
                        width={350}
                        headerText={localize("SkapaInnehall")}
                        onDismiss={this.hidePopouts.bind(this)}
                    >
                        <div className="PopoutFrame-content-inner">
                            <CreateWizard
                                modalLess
                                onDismiss={() => {
                                    this.setState({ showCreateWizard: false });
                                }}
                            />
                        </div>
                    </SpintrStaticLinksPopout>
                )}
                <SpintrUserMenu user={this.props.currentUser} />
                {!this.props.disableCoachmarks && (
                    <SpintrCoachmark
                        refDiv={this.shortcutsButtonRef}
                        refIsInHeader
                        text={localize("SHORTCUTS_BUTTON_INFO")}
                        coachmarkId={"SHORTCUTS_BUTTON_INFO"} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    primaryFGColor: state.instance.get("color"),
    accentFGColor: state.instance.get("accentColor"),
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    notificationsUnreadCount: state.notification.unread,
    unreadCountPersonConversations:
        (state.chat.conversations.items || []).filter((c: any) => c.type !== 3).length > 0
            ? (state.chat.conversations.items || []).filter((c: any) => c.type !== 3 && c.unread > 0).length
            : state.chat.conversations.unreadCountPersonConversations,
    disablePersonChat: state.instance.get("disablePersonChat"),
    displayAsOfflineInChat: state.ui.displayAsOfflineInChat,
    unreadEmails: state.profile.unreadEmails,
    googleConnected: state.profile.active.googleConnected,
    office365Connected: state.profile.active.office365Connected,
    exchangeWebmailUrl: state.instance.get("exchangeWebmailUrl"),
    emailEnabled:
        state.instance.get("enableExternalMailLink") != "false" &&
        (state.profile.active.googleConnected ||
            (state.profile.active.roles.includes("aduser") &&
                (state.instance.get("enableExchange") ||
                    (state.profile.active.office365Connected && state.instance.get("office365Enabled"))))),
    operatingInfoEnabled: state.app.items.some(
        (app) => app.enabled && app.id === SpintrTypes.SpintrApp.OperatingInfo,
    ),
    dashboardEnabled: state.app.items.some(
        (app) => app.enabled && app.id === SpintrTypes.SpintrApp.Dashboard,
    ),
    displayDashboardObjectsInHeader: state.instance.get("displayDashboardObjectsInHeader"),
    importantArticles: state.informationFeed.importantArticles.filter(x => x.priorityLevel === 5),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SpintrStaticLinks));
