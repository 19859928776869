import { Checkbox, Stack, StackItem } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { FormControl, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { Style } from "src/ui/helpers";
import SpintrDatePicker from "../SpintrDatePicker";
import { SpintrTypes } from "src/typings";

interface IProps {
    content: Spintr.ISocialSystemStatus;
    post: Spintr.ISocialPostBase;
    onAttachedContentUpdate?: any;
    onPostUpdate?: any;
    onRemove?: any;
    disableEditing?: boolean;
}

interface IState {}

class Visage2ComposerSystemStatus extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render(): ReactNode {
        return (
            <div className="Visage2ComposerSystemStatus">
                <FormControl>
                    <SpintrDatePicker
                        label={localize("Publicera")}
                        value={this.props.content.publishStartDate}
                        onChangeHandler={(date) => {
                            this.props.onAttachedContentUpdate({
                                ...this.props.content,
                                publishStartDate: date,
                            });
                        }}
                        withPortal
                    />
                </FormControl>
                <Stack horizontal grow tokens={{ childrenGap: Style.getSpacing(3) }}>
                    <StackItem grow>
                        <FormControl>
                            <SpintrDatePicker
                                label={localize("Startar")}
                                value={this.props.content.startDate}
                                onChangeHandler={(date) => {
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        startDate: date,
                                    });
                                }}
                                withPortal
                            />
                        </FormControl>
                    </StackItem>
                    <StackItem grow>
                        <FormControl>
                            <SpintrDatePicker
                                label={localize("Slutar")}
                                value={this.props.content.endDate}
                                onChangeHandler={(date) => {
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        endDate: date,
                                    });
                                }}
                                withPortal
                                isClearable
                            />
                        </FormControl>
                    </StackItem>
                </Stack>
                <FormControl>
                    <FormTokenizedObjectInput
                        items={this.props.content.responsibleUser ? [this.props.content.responsibleUser] : []}
                        label={localize("RESPONSIBLE_PERSON")}
                        types={[SpintrTypes.UberType.User]}
                        itemLimit={1}
                        onChange={(p) => {
                            this.props.onAttachedContentUpdate({
                                ...this.props.content,
                                responsibleUser: p != null && p.length > 0 ?
                                    {
                                        ...p[0],
                                        id: p[0].key
                                    } :
                                    undefined
                            });
                        }}
                    />
                </FormControl>
                {/*}
                <FormControl>
                    <Checkbox
                        label={localize("Fast")}
                        checked={this.props.post.isPinned}
                        onChange={(ev, checked) => {
                            this.props.onPostUpdate({
                                ...this.props.post,
                                isPinned: checked,
                            });
                        }}
                    />
                </FormControl>
                */}
                <FormControl>
                    <Checkbox
                        label={localize("Prioriterad2")}
                        checked={this.props.content.prioritized}
                        onChange={(ev, checked) => {
                            this.props.onAttachedContentUpdate({
                                ...this.props.content,
                                prioritized: checked,
                            });
                        }}
                    />
                </FormControl>
            </div>
        );
    }
}

export default Visage2ComposerSystemStatus;
