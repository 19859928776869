import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import React, { FormEventHandler, Fragment, ReactElement, useCallback, useMemo } from "react";
import { localize } from "src/l10n";
import { FormControl } from "src/ui/components/Forms";
import { PlannerItemFormProps } from "./PlannerItemForm.types";
import { PlannerModel } from "../types";
import { SpintrTypes } from "src/typings";
import { PlannerFilePicker } from "../PlannerFilePicker";
import { getAvailableFileTypes, PlannerFileTypes } from "../utils";


function getMarketingOptions(): IDropdownOption<SpintrTypes.PlannerMarketingChannel>[] {
    return [{
        key: SpintrTypes.PlannerMarketingChannel.Email,
        text: localize("PLANNER_CAMPAIGN_MARKETING_EMAIL"),
        data: SpintrTypes.PlannerMarketingChannel.Email,
    }, {
        key: SpintrTypes.PlannerMarketingChannel.SocialMedia,
        text: localize("PLANNER_CAMPAIGN_MARKETING_SOCIAL"),
        data: SpintrTypes.PlannerMarketingChannel.SocialMedia,
    }, {
        key: SpintrTypes.PlannerMarketingChannel.Store,
        text: localize("PLANNER_CAMPAIGN_MARKETING_STORE"),
        data: SpintrTypes.PlannerMarketingChannel.Store,
    }];
}

function PlannerCampaignForm(props: PlannerItemFormProps<Spintr.PlannerCampaign>): ReactElement {
    const { errors, model, onModelUpdate } = props

    const onTextFieldChanged = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
            if (!event?.target) {
                return;
            }

            const { name, value } = (event.target as HTMLInputElement | HTMLTextAreaElement);

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: value },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    const marketingOptions = useMemo(getMarketingOptions, []);

    const onMarketingChannelsChanged = useCallback(
        (_: unknown, option: IDropdownOption) => onModelUpdate((prevModel) => Object.assign(
            { ...prevModel },
            {
                marketingChannels: option.selected
                    ? (prevModel.marketingChannels || []).concat(option.key as SpintrTypes.PlannerMarketingChannel)
                    : (prevModel.marketingChannels || []).filter((channel) => channel !== option.key),
            },
        ) as PlannerModel),
        [onModelUpdate],
    );

    if (model.itemType !== SpintrTypes.PlannerItemType.Campaign) {
        return null;
    }

    return (
        <Fragment>
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_CAMPAIGN_GOALS")}
                    name="campaignGoals"
                    multiline={true}
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.campaignGoals}
                    value={model.campaignGoals || ""}
                />
            </FormControl>

            <FormControl>
                <Dropdown
                    label={localize("PLANNER_CAMPAIGN_MARKETING_CHANNELS")}
                    multiSelect={true}
                    onChange={onMarketingChannelsChanged}
                    options={marketingOptions}
                    selectedKeys={model.marketingChannels ?? []} />
            </FormControl>

            {/* Promotion codes */}

            <FormControl label={localize("PLANNER_CAMPAIGN_MATERIAL")} tooltipText={getAvailableFileTypes()}>

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={SpintrTypes.PlannerItemType.Campaign}
                    fieldType={SpintrTypes.PlannerCampaignFileType.CampaignMaterial}
                    onModelUpdate={onModelUpdate}
                    simple={false} />

            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_CAMPAIGN_AUDIENCE")}
                    multiline={true}
                    name="targetAudience"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.targetAudience}
                    value={model.targetAudience || ""}
                />
            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_CAMPAIGN_MESSAGE")}
                    name="primaryMessage"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.primaryMessage}
                    value={model.primaryMessage || ""}
                />
            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_CAMPAIGN_PLAN")}
                    name="campaignPlan"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.campaignPlan}
                    value={model.campaignPlan || ""}
                />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={SpintrTypes.PlannerItemType.Campaign}
                    fieldType={SpintrTypes.PlannerCampaignFileType.CampaignPlan}
                    onModelUpdate={onModelUpdate}
                    simple={true} />

            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_CAMPAIGN_REACH")}
                    name="expectedReach"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.expectedReach}
                    value={model.expectedReach || ""}
                />
            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_CAMPAIGN_PARTNERS")}
                    multiline={true}
                    name="campaignPartners"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.campaignPartners}
                    value={model.campaignPartners || ""}
                />
            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_CAMPAIGN_LEGAL")}
                    multiline={true}
                    name="legalComplianceInfo"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.legalComplianceInfo}
                    value={model.legalComplianceInfo || ""} />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={SpintrTypes.PlannerItemType.Campaign}
                    fieldType={SpintrTypes.PlannerCampaignFileType.LegalComplianceInfo}
                    onModelUpdate={onModelUpdate}
                    simple={true} />
            </FormControl>
        </Fragment>
    );
}

export default PlannerCampaignForm;
