import React, {  } from "react";
import "./SystemStatusResource.scss";
import SystemStatusResourceInfo from "./SystemStatusResourceInfo";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Link } from "react-router-dom";
import SystemStatusResourceCTA from "./SystemStatusResourceCTA";

interface IProps {
    resource: Spintr.ISystemStatusResource;
}

const SystemStatusResource = (props: IProps) => {
    return (
        <Link className="SystemStatusResource" to={"/operations/" + props.resource.id}>
            <SystemStatusResourceInfo resource={props.resource} />
            <div className="footer">
                <div className="cta">
                    <SystemStatusResourceCTA
                        resource={props.resource} />
                </div>
                <div className="footer-button">
                    <Visage2Icon icon="arrow-right-3" size="small" />
                </div>
            </div>
        </Link>
    )
}

export default SystemStatusResource;
