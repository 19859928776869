import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localize } from "src/l10n";
import { VisageSidebarMode, setSidebarMode } from "src/sidebar";
import api from "src/spintr/SpintrApi";
import { Label, PageHeader } from "src/ui";
import "./SystemStatusStartView.scss";
import { SpintrTypes } from "src/typings";
import { Dropdown, Modal } from "@fluentui/react";
import CalypsoContentWithSidebar from "src/ui/components/CalypsoContentWithSidebar";
import SpintrLoader from "src/ui/components/Loader";
import SystemStatusResource from "./SystemStatusResource";
import SystemStatus from "./SystemStatus";
import { FormControl, FormSection } from "src/ui/components/Forms";
import PopupHeader from "src/ui/components/PopupHeader";
import SystemStatusForm from "./SystemStatusForm";
import Axios, { CancelToken } from "axios";
import { debounce } from "src/utils";
import { IContentHeaderButton } from "src/ui/components/PageHeader";
import classNames from "classnames";
import { Conditional } from "src/components/Conditional";
import { ConditionalRender } from "src/components/ConditionalRender";

type StateProps = {
    currentUser:        Spintr.IActiveUser;
    viewMode:           SpintrTypes.ViewMode;
    isSmallViewMode:    boolean;
};

type ActiveSystemStatus = {
    endDate: string | Date;
    id: number;
    prioritized: boolean;
    startDate: string | Date;
    status: number;
    text: string;
}

type SystemStatusResource
    = Spintr.ISystemStatusResource
    & { activeSystemStatuses: ActiveSystemStatus[] };

interface ISystemStatusResourceCategory {
    id: number;
    name: string;
    isActive?: boolean;
    resources: SystemStatusResource[];
}

const sidebarStartsExpanded = true;

function SystemStatusStartView() {
    const contentRef = useRef<any>();
    const dispatch = useDispatch();

    const {
        currentUser,
        viewMode,
        isSmallViewMode,
    } = useSelector<Spintr.AppState, StateProps>((appState) => ({
        currentUser: appState.profile.active,
        viewMode: appState.ui.viewMode,
        isSmallViewMode: appState.ui.isSmallViewMode,
    }));

    /* Different state things */
    const [searchText, setSearchText] = useState<string>();
    const [expandSidebarIcon, setExpandSidebarIcon] = useState(sidebarStartsExpanded ? "sidebar-right" : "sidebar-left");
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [categoryId, setCategoryId] = useState<number>(0);
    const [status, setStatus] = useState<number>(-1);
    const [showNewResourceModal, setShowNewResourceModal] = useState(false);
    const [newResource, setNewResource] = useState<Spintr.ISystemStatusResource>();
    const [helpContent, setHelpContent] = useState<Spintr.HelpContent | undefined>(undefined);

    const debouncedSetSearchText = useMemo(() => debounce(setSearchText, 250), [setSearchText]);

    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();

        api.get<Spintr.HelpContent>("/api/v1/help/10", { cancelToken: cancelTokenSource.token })
            .then((response) => setHelpContent(response.data))
            .catch(() => {
                // Ignore
            });

        return () => cancelTokenSource.cancel();
    }, [setHelpContent]);

    const fetch = useCallback(async (cancelToken?: CancelToken) => {
        setIsLoading(true);

        const params = {
            searchText,
            categoryId: categoryId > 0 ? categoryId : undefined,
            status: status > -1 ? status : undefined
        };

        try {
            const response = await api.get(
                "/api/v1/systemstatuses/resources",
                { params, cancelToken }
            );

            setData(response.data);
            setIsLoading(false);
        } catch (err) {
            return;
        }
    }, [searchText, categoryId, status]);

    const toggleShowNewResourceModal = useCallback((id?: number) => {
        // @ts-ignore
        setNewResource({
            id,
            targets: [],
            owners: [
                {
                    id: currentUser.id,
                    key: currentUser.id,
                    name: currentUser.name,
                    imageUrl: currentUser.images.feedComposer,
                    subText: currentUser.department.name,
                },
            ],
            faq: [],
            categoryId: -1
        });
        setShowNewResourceModal(!showNewResourceModal);
    }, [showNewResourceModal, currentUser]);

    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();

        fetch(cancelTokenSource.token);

        return () => cancelTokenSource.cancel();
    }, [fetch]);

    useEffect(() => {
        dispatch(setSidebarMode(VisageSidebarMode.noMenu));

        return () => dispatch(setSidebarMode(VisageSidebarMode.menu));
    }, []);

    useEffect(() => {
        if (viewMode < SpintrTypes.ViewMode.PhoneLandscape) {
            if (expandSidebarIcon === "sidebar-right") {
                setExpandSidebarIcon(
                    contentRef.current.toggleIsExpanded(),
                );
            }
        }
    }, [viewMode]);

    const categories : ISystemStatusResourceCategory[] = useMemo(() => {
        const activeResources = data.filter(x => x.status > 0);
        let categories: ISystemStatusResourceCategory[] = [];

        for (let resource of data) {
            if (activeResources.find(x => x.id === resource.id)) {
                continue;
            }

            const foundCategory = categories.find(x => x.id === (resource.categoryId || -1));

            if (foundCategory) {
                foundCategory.resources.push(resource);
            } else {
                categories.push({
                    id: resource.categoryId || -1,
                    name: resource.categoryName || localize("Alla"),
                    isActive: false,
                    resources: [resource]
                })
            }
        }

        categories = categories.sort((a, b) => (a.name > b.name ? 1 : -1));

        return [
            ...(activeResources.length > 0 ?
                [{
                    id: 0,
                    name: localize("Aktiva"),
                    isActive: true,
                    resources: activeResources
                }] :
                []
            ),
            ...categories
        ];
    }, [data]);

    const renderSidebar = useCallback(() => (
        <div className="SystemStatusStartView-sidebar">
            <FormSection>
                <ConditionalRender condition={helpContent?.text && helpContent.text.length > 0}>
                    {() => (
                        <div className="help-content">
                            <Label size="h6" color="mid-grey" weight="medium" className="title">
                                {helpContent.name}
                            </Label>
                            <Label size="body-2" color="grey" className="resource-description">
                                {helpContent.text}
                            </Label>
                        </div>
                    )}
                </ConditionalRender>
                <FormControl>
                    <Dropdown
                        label={localize("Status")}
                        selectedKey={status}
                        onChange={(_e, v) => {
                            setStatus(Number(v.key));
                        }}
                        options={[{
                            key: -1,
                            text: localize("Alla")
                        }, {
                            key: SpintrTypes.SystemStatusType.AllClear,
                            text: localize("ALL_CLEAR")
                        }, {
                            key: SpintrTypes.SystemStatusType.Ongoing,
                            text: localize("Pagaende")
                        }, {
                            key: SpintrTypes.SystemStatusType.Done,
                            text: localize("Avklarad")
                        }, {
                            key: SpintrTypes.SystemStatusType.Planned,
                            text: localize("Planerad")
                        }]}
                        styles={{ dropdown: { width: 250 } }}
                    />
                </FormControl>
                <FormControl>
                    <Dropdown
                        label={localize("Kategori")}
                        selectedKey={categoryId}
                        onChange={(_e, v) => {
                            setCategoryId(Number(v.key));
                        }}
                        options={[
                            {
                                key: 0,
                                text: localize("Alla")
                            },
                            ...categories.filter(x => x.id > 0).map((c) => {
                                return {
                                    key: c.id,
                                    text: c.name
                                }
                            })
                        ]}
                        styles={{ dropdown: { width: 250 } }}
                    />
                </FormControl>
            </FormSection>
        </div>
    ), [status, categoryId, categories, setCategoryId]);

    const headerButtons = useMemo<IContentHeaderButton[]>(() => {
        const buttons: IContentHeaderButton[] = [{
            key: "expand",
            title: localize("Filtrera"),
            onClick: () => {
                setExpandSidebarIcon(contentRef.current.toggleIsExpanded())
            },
            icon: expandSidebarIcon
        }];

        if (currentUser.roles.includes("administrators") || currentUser.roles.includes("editor")) {
            buttons.unshift({
                key: "add",
                text: localize("SkapaNyResurs"),
                onClick: () => {
                    toggleShowNewResourceModal();
                },
                iconProps: { iconName: "Add" },
                className: "commandBarAddButton",
                theme: "primary"
            })
        }

        return buttons;
    }, [toggleShowNewResourceModal, currentUser, expandSidebarIcon, setExpandSidebarIcon, contentRef]);

    return (
        <CalypsoContentWithSidebar
            innerRef={contentRef}
            renderSideBar={renderSidebar}
            sidebarStartsExpanded={sidebarStartsExpanded}
        >
            <div className={classNames("SystemStatusStartView", { "with-sidebar": expandSidebarIcon === "sidebar-right" })}>
                <PageHeader
                    title={localize("appSystemStatus")}
                    displaySearch={true}
                    onSearchQueryChange={debouncedSetSearchText}
                    buttons={headerButtons}
                />
                {isLoading && <SpintrLoader />}
                {!isLoading && (
                    <div className="categories">
                        {categories.map((c: ISystemStatusResourceCategory) => (
                            <div key={c.id} className="category">
                                <Label color="primaryContent" size="body-2">{c.name}</Label>
                                <div className="resources">
                                    {c.resources.flatMap((r) => r.status === 0
                                        ? [(
                                            <div key={r.id}>
                                                <SystemStatusResource key={r.id} resource={r} />
                                            </div>
                                        )] : r.activeSystemStatuses
                                                .filter((s) => s.status === SpintrTypes.SystemStatusType.Ongoing || s.status === SpintrTypes.SystemStatusType.Planned)
                                                .map((s) => (
                                                    <div key={s.id}>
                                                        <SystemStatus resource={r} systemStatus={s} />  
                                                    </div>
                                                ))
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {!isLoading && categories.length === 0 && (
                    <div className="spintr-list-empty-list">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                            {localize("IngaPoster")}
                        </Label>
                    </div>
                )}
            </div>
            <Modal
                className="spintr-modal modalWithPopupHeader system-status-resource-modal"
                isOpen={showNewResourceModal}
                onDismiss={() => {
                    setShowNewResourceModal(false);
                }}
            >
                <PopupHeader
                    text={localize("SYSTEM_STATUS_RESOURCES_MODAL_HEADER_" + (newResource?.id ? "EDIT" : "CREATE"))}
                    onClose={() => {
                        setShowNewResourceModal(false);
                    }}
                />
                <div className="popup-inner">
                    <SystemStatusForm
                        id={newResource?.id}
                        onDone={() => {
                            setShowNewResourceModal(false);
                            fetch();
                        }}
                        onCancel={() => {
                            setShowNewResourceModal(false);
                        }} />
                </div>
            </Modal>
        </CalypsoContentWithSidebar>
    )
};

export default SystemStatusStartView;
